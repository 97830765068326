import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';
import { Button } from '@mtabnpd/mtab-platform-core';
import { Navigate } from 'react-router-dom';

import { Container } from 'components/container';
import { Text, FontSize } from 'components/text';
import { ROUTE_DEFAULT } from 'constants/routes';

import './Login.scss';

export function Login() {
  const { authState, oktaAuth } = useAuthProvider();

  const login = () => {
    oktaAuth.signInWithRedirect({ originalUri: ROUTE_DEFAULT });
  };

  if (!authState) {
    return null;
  }

  if (!authState.isAuthenticated) {
    return (
      <Container className="App-login" flex column>
        <Text header size={FontSize.HUGE}>Log in to your mTab account</Text>
        <Button className="App-login__button" onClick={login}>Login</Button>
      </Container>
    );
  }

  return <Navigate to={ROUTE_DEFAULT} replace />;
}
