import { INDUSTRY_ATTRIBUTE_VALUE_OTHER } from 'constants/app';
import { UserProfile, UserSubscriptionType } from 'types/user';

import { UserSetupFormState } from './userSetupForm';

type ValidateUserSetupParameters = {
  userSubscriptionType: UserSubscriptionType | undefined;
  requiresInterests: boolean;
};
export function validateUserSetup(
  userSetupState: UserSetupFormState,
  parameters: ValidateUserSetupParameters
): boolean {
  const { userSubscriptionType, requiresInterests } = parameters;

  if (userSubscriptionType === UserSubscriptionType.TRIAL && !userSetupState.orgName) {
    return false;
  }

  if (requiresInterests && !userSetupState.interests?.length) {
    return false;
  }

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (userSetupState.interests?.includes(INDUSTRY_ATTRIBUTE_VALUE_OTHER.valueId)
    && !userSetupState.otherInterest?.trim()) {
    return false;
  }

  return true;
}

export function shouldIntercept(user: Partial<Pick<UserProfile, 'userSetup'>>, parameters: { requiresIndustries?: boolean }) {
  const { requiresIndustries } = parameters;
  const { userSetup } = user;
  const { acceptedTermsAndConditions } = userSetup ?? {};

  return !acceptedTermsAndConditions || requiresIndustries;
}

export function hasIndustries(profile?: UserProfile) {
  const { userSetup } = profile ?? {};

  if (userSetup) {
    if (userSetup.userIndustries.length > 0) {
      return true;
    }

    if (userSetup.tenantIndustries.length > 0) {
      return true;
    }

    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (userSetup.otherIndustry !== undefined) {
      return true;
    }
  }

  return false;
}
