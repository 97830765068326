import { useRef } from 'react';
import { AppPromptKey, UserProfile, UserSubscriptionStatus } from 'types/user';

export function useShouldShowPrompt(profile: UserProfile | undefined) {
  const {
    userSetup,
    prompts = [],
    mtabPlusSubscription
  } = profile ?? {};
  const disclaimerAcceptedInPrevSession = useRef<boolean>();

  if (userSetup && disclaimerAcceptedInPrevSession.current === undefined) {
    disclaimerAcceptedInPrevSession.current = userSetup.acceptedTermsAndConditions;
  }

  return !!disclaimerAcceptedInPrevSession.current
    && prompts.includes(AppPromptKey.MTAB_PLUS)
    && mtabPlusSubscription?.status === UserSubscriptionStatus.TRIALING;
}
