import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SecurityContext, toRelativeUrl } from '@mtabnpd/mtab-client-auth-js';

import { ROUTE_DEFAULT } from 'constants/routes';
import { authProvider } from 'lib/auth';
import { removeBaseName } from 'utilities/url';

export function AppSecurityContext({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const restoreOriginalUri = useCallback((_oktaAuth: any, originalUri: string | null) => {
    navigate(
      toRelativeUrl(
        removeBaseName(originalUri ?? ROUTE_DEFAULT),
        window.location.origin
      ),
      { replace: true }
    );
  }, [navigate]);

  return (
    <SecurityContext
      provider={authProvider}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </SecurityContext>
  );
}
