import { FeedContentItem, FilterOperator } from 'types/feed';

type ConstructFilterQueryResult = [
  fields: (keyof FeedContentItem)[],
  values: string[],
  operators: FilterOperator[]
];

export function constructFilterQuery(
  filters: Partial<Omit<FeedContentItem, 'metadata'>>
): ConstructFilterQueryResult {
  return Object.entries(filters)
    .reduce<ConstructFilterQueryResult>((result, [field, value]) => {
      const [fields, values, operators] = result;

      fields.push(field as keyof FeedContentItem);
      values.push(value);
      operators.push(FilterOperator.EQUAL);

      return result;
    }, [[], [], []]);
}
