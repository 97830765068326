import { useEffect } from 'react';
import { Input } from '@mtabnpd/mtab-design-system';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useLatest from '@react-hook/latest';
import classNames from 'classnames';

import { Form, Field } from 'components/form';
import { FontSize, Text } from 'components/text';
import { UserInterests } from 'components/userInterests';
import { INDUSTRY_ATTRIBUTE_MAX, INDUSTRY_ATTRIBUTE_VALUE_OTHER } from 'constants/app';
import { useIndustryAttribute } from 'hooks/userSettings';

import { useValidationRules } from './UserSetupForm.hooks';
import { useSetupFormLabelStyles } from './UserSetupForm.styles';

import './UserSetupForm.scss';

interface UserSetupFormProps {
  requiredOrgName: boolean;
  requiredInterests: boolean;
  onChange?: (state: UserSetupFormState, isValid: boolean) => void;
}

export interface UserSetupFormState {
  orgName?: string;
  interests?: string[];
  otherInterest?: string;
}

enum FieldName {
  ORG = 'orgName',
  INTERESTS = 'interests',
  OTHER_INTEREST = 'otherInterest'
}

export function UserSetupForm({
  onChange,
  requiredOrgName,
  requiredInterests
}: UserSetupFormProps) {
  const intl = useIntl();
  const fieldLabelStyles = useSetupFormLabelStyles();
  const onChangeRef = useLatest(onChange);
  const {
    control,
    setValue,
    watch,
    getValues,
    formState,
    trigger
  } = useForm<UserSetupFormState>({
    mode: 'onBlur',
    defaultValues: {
      [FieldName.ORG]: '',
      [FieldName.INTERESTS]: [],
      [FieldName.OTHER_INTEREST]: ''
    }
  });
  const { errors, isValid, touchedFields } = formState;
  const {
    orgName: orgNameError,
    otherInterest: otherInterestError
  } = errors;
  const industries = useIndustryAttribute();
  const showOther = watch(FieldName.INTERESTS)?.includes(INDUSTRY_ATTRIBUTE_VALUE_OTHER.valueId);
  const {
    interestsRules,
    orgNameRules,
    otherInterestRules
  } = useValidationRules(showOther, Boolean(touchedFields.otherInterest));

  useEffect(() => {
    const subscription = watch((values, info) => {
      if (info.name === FieldName.INTERESTS) {
        trigger(FieldName.OTHER_INTEREST);
      }

      onChangeRef.current?.(values as UserSetupFormState, isValid);
    });

    return subscription.unsubscribe;
  }, [onChangeRef, watch, trigger, isValid]);

  useEffect(() => {
    onChangeRef.current?.(getValues(), isValid);
  }, [onChangeRef, getValues, isValid]);

  useEffect(() => {
    if (showOther) {
      trigger('otherInterest');
    }
  }, [showOther, trigger]);

  if (!requiredOrgName && !requiredInterests) {
    return null;
  }

  return (
    <Form className="App-user-setup__form">
      {requiredOrgName && (
        <Field
          required
          label={{
            className: fieldLabelStyles.root,
            children: (
              <Text header size={FontSize.LARGE} inline>
                <FormattedMessage defaultMessage="Your organization" description="Input title" />
              </Text>
            )
          }}
          validationState={orgNameError ? 'error' : undefined}
          validationMessage={orgNameError?.message}
          orientation="vertical"
        >
          <Controller
            name={FieldName.ORG}
            control={control}
            rules={orgNameRules}
            render={({ field }) => (
              <Input id={FieldName.ORG} {...field} />
            )}
          />
        </Field>
      )}
      {requiredInterests && (
        <Field
          required={requiredInterests}
          className="App-user-setup__interests"
          label={{
            className: fieldLabelStyles.root,
            children: (
              <Text header size={FontSize.LARGE}>
                <FormattedMessage defaultMessage="Your areas of interest" description="Input title" />
              </Text>
            )
          }}
          orientation="vertical"
        >
          <Controller
            name={FieldName.INTERESTS}
            control={control}
            rules={interestsRules}
            render={() => (
              <UserInterests
                interests={industries}
                max={INDUSTRY_ATTRIBUTE_MAX}
                showOther
                onSelectionChange={(selectedInterests) => {
                  setValue(
                    'interests',
                    selectedInterests,
                    { shouldDirty: true, shouldTouch: true, shouldValidate: true }
                  );
                }}
              />
            )}
          />
        </Field>
      )}
      <Field
        className={classNames('App-user-setup__other-interest', showOther && 'App-user-setup__other-interest--visible')}
        validationState={otherInterestError ? 'error' : undefined}
        validationMessage={otherInterestError?.message}
        orientation="vertical"
      >
        <Controller
          name={FieldName.OTHER_INTEREST}
          control={control}
          rules={otherInterestRules}
          render={({ field }) => (
            <Input
              {...field}
              disabled={!showOther}
              readOnly={!showOther}
              placeholder={intl.formatMessage({ defaultMessage: 'Please specify...', description: 'Other input' })}
              aria-label="Please specify - other interests"
            />
          )}
        />
      </Field>
    </Form>
  );
}
