import { useState } from 'react';
import { AppHeader as MtabAppHeader } from '@mtabnpd/mtab-app-header';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, Link } from 'react-router-dom';
import classNames from 'classnames';

import { getInfoHeaderMenuDefinition } from 'components/infoHeaderMenu';
import { TenantSwitcher } from 'components/tenantSwitcher';
import { Impersonation } from 'components/impersonation';
import { SubscriptionDetails } from 'components/subscriptionDetails';
import { APP_PLATFORM } from 'constants/app';
import { ROUTE_DEFAULT, ROUTE_SETTINGS_USER } from 'constants/routes';
import { useClearUserImpersonationMutation, useGetUserProfile } from 'services/api/user';
import { selectUserBadges } from 'services/api/user/selectors';
import { UserProfile, UserSubscriptionType } from 'types/user';
import { withBasename } from './utilities/url';

import { useSearch } from './AppHeader.hooks';
import { useUserClaims } from './hooks/userContext';

type UseGetUserContextOptions = {
  onImpersonate?: any;
  onSwitchUser?: any;
  profile?: UserProfile;
  paymentsUrl: string;
  subscription: any;
};

function useGetUserContext({
  profile,
  onImpersonate,
  onSwitchUser,
  paymentsUrl,
  subscription
}: UseGetUserContextOptions) {
  const intl = useIntl();
  const { oktaAuth } = useAuthProvider();
  const userImpersonation = profile?.impersonation;

  if (!profile) {
    return undefined;
  }
  const isTypeSubscribed = subscription?.type === UserSubscriptionType.SUBSCRIBED;
  const freeUserSubscribeButton = isTypeSubscribed && subscription.detail.cost === 0 && (
    <SubscriptionDetails
      paymentsUrls={`${paymentsUrl}/subscribe/insight-cloud`}
      subscriptionButtonText={(
        <FormattedMessage
          defaultMessage="Subscribe"
          description="Button label for subscribe button"
        />
      )}
    />
  );

  return {
    user: {
      displayName: profile.displayName,
      tenantName: profile.tenantLabel,
      badges: selectUserBadges({ data: profile }),
      isImpersonating: userImpersonation?.impersonating
    },
    avatar: {
      className: 'app-user-avatar'
    },
    menu: {
      links: [
        {
          component: Link,
          to: ROUTE_SETTINGS_USER,
          label: intl.formatMessage({ defaultMessage: 'Account settings', description: 'User menu item' })
        }
      ],
      onSwitchUser,
      onImpersonate,
      logout: () => oktaAuth.signOut(),
      children: freeUserSubscribeButton
    }
  };
}

export function AppHeader({ interactive = true }: { interactive?: boolean }) {
  const intl = useIntl();

  const { authState } = useAuthProvider();
  const { superAdmin } = useUserClaims();
  const { data: profile } = useGetUserProfile();
  const [clearUserImpersonation] = useClearUserImpersonationMutation();

  const navigate = useNavigate();

  const [tenantSwitcherIsOpen, setTenantSwitcherIsOpen] = useState(false);
  const [impersonationModalIsOpen, setImpersonationModalIsOpen] = useState(false);

  const [searchTerm, searchTermIsValid, setSearchTerm, handleSearch] = useSearch();

  const {
    impersonation: userImpersonation,
    subscription,
    paymentsUrl = '',
    tenantOptions: tenants = []
  } = profile ?? {};

  const canImpersonate = userImpersonation?.canImpersonate || userImpersonation?.impersonating;
  const canSwitchTenant = superAdmin || (!(userImpersonation?.impersonating) && tenants.length > 0);

  const userContext = useGetUserContext({
    paymentsUrl,
    subscription,
    profile,
    onImpersonate: canImpersonate ? () => {
      if (userImpersonation.impersonating) {
        clearUserImpersonation();
        return;
      }

      setImpersonationModalIsOpen(true);
    } : undefined,
    onSwitchUser: canSwitchTenant ? () => {
      setTenantSwitcherIsOpen(true);
    } : undefined
  });

  if (!interactive || authState === null) {
    return (
      <MtabAppHeader />
    );
  }

  return (
    <>
      <MtabAppHeader
        app={{
          appName: APP_PLATFORM,
          href: withBasename(ROUTE_DEFAULT),
          onClick: (e) => {
            navigate(ROUTE_DEFAULT);
            e.preventDefault();
          }
        }}
        search={{
          className: classNames('mtab-app-header__search', {
            'mtab-app-header__search--error': !searchTermIsValid
          }),
          value: searchTerm,
          onChange: (e) => {
            setSearchTerm((e.target as HTMLInputElement).value);
          },
          onSearch: handleSearch,
          pattern: '.{2,}',
          title: searchTermIsValid ? searchTerm : intl.formatMessage({
            defaultMessage: 'Search value must be longer than 1',
            description: 'Search validation'
          })
        }}
        userContext={userContext}
        appInfo={authState.isAuthenticated ? getInfoHeaderMenuDefinition() : undefined}
        i18n={{
          labels: {
            impersonateButton: userImpersonation?.impersonating ? (
              intl.formatMessage({ defaultMessage: 'Login as original user', description: 'Button label' })
            ) : (
              intl.formatMessage({ defaultMessage: 'Login as another user', description: 'Button label' })
            )
          }
        }}
      />
      <TenantSwitcher
        mode="modal"
        visible={tenantSwitcherIsOpen}
        onAccept={() => setTenantSwitcherIsOpen(false)}
        onClose={() => setTenantSwitcherIsOpen(false)}
        tenants={tenants}
      />
      <Impersonation
        mode="modal"
        visible={impersonationModalIsOpen}
        onApply={() => setImpersonationModalIsOpen(false)}
        onClose={() => setImpersonationModalIsOpen(false)}
      />
    </>
  );
}
