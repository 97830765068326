import { errorNotification } from 'actions/notifications';
import { HTTP_STATUS_NO_CONTENT } from 'constants/http';
import { authProvider } from 'lib/auth';
import { store } from 'store';
import { getErrorLabel } from 'utilities/httpErrorMessages';

interface ResponseTransformer<T> {
  (response: Response): Promise<T> | T;
}

export function addAuth(options: RequestInit = {}): RequestInit {
  const authorization = `Bearer ${authProvider.auth.getAccessToken()}`;

  options.headers = {
    ...options.headers,
    Authorization: authorization
  };

  return options;
}

export function jsonTransformer<T>(response: Response): Promise<T> {
  return response.json() as Promise<T>;
}

export function request<T>(
  url: string,
  options: RequestInit,
  responseAccessor: ResponseTransformer<T> = jsonTransformer
): Promise<T> {
  return fetch(url, options)
    .then((response) => {
      if (!response.ok || response.status === HTTP_STATUS_NO_CONTENT) {
        return response.json()
          .then(
            ({ message }) => {
              store.dispatch(errorNotification({ message: getErrorLabel(response.status), description: message }));
              throw new Error(`Request failed with status: ${response.statusText || response.status}, message: ${message}`);
            },
            () => {
              store.dispatch(errorNotification({ message: getErrorLabel(response.status) }));
              throw new Error(`Request failed with status: ${response.statusText || response.status}`);
            }
          );
      }

      return responseAccessor(response);
    });
}

export function authedGet<T>(
  url: string,
  data?: object,
  options?: RequestInit,
  accessor?: ResponseTransformer<T>
): Promise<T> {
  return get(url, data, addAuth(options), accessor);
}

export function get<T>(
  url: string,
  data?: object,
  options: RequestInit = {},
  accessor?: ResponseTransformer<T>
): Promise<T> {
  options.method = 'GET';

  return request(url, options, accessor);
}

export function authedPost<T, D = object>(
  url: string,
  data?: D,
  options?: RequestInit,
  accessor?: ResponseTransformer<T>
): Promise<T> {
  return post(url, data, addAuth(options), accessor);
}

export function post<T, D = object>(
  url: string,
  data?: D,
  options: RequestInit = {},
  accessor?: ResponseTransformer<T>
): Promise<T> {
  options.method = 'POST';

  if (!data) {
    return request(url, options, accessor);
  }

  return request(url, setJsonBody(data, options), accessor);
}

export function authedPut<T, Data extends object = object>(
  url: string,
  data?: Data,
  options?: RequestInit,
  accessor?: ResponseTransformer<T>
): Promise<T> {
  return put(url, data, addAuth(options), accessor);
}

export function put<T, Data extends object = object>(
  url: string,
  data?: Data,
  options: RequestInit = {},
  accessor?: ResponseTransformer<T>
): Promise<T> {
  options.method = 'PUT';

  if (!data) {
    return request(url, options, accessor);
  }

  return request(url, setJsonBody(data, options), accessor);
}

export function authedDelete<T>(
  url: string,
  data?: object,
  options?: RequestInit,
  accessor?: ResponseTransformer<T>
): Promise<T> {
  return del(url, data, addAuth(options), accessor);
}

export function del<T>(
  url: string,
  data?: object,
  options: RequestInit = {},
  accessor?: ResponseTransformer<T>
): Promise<T> {
  options.method = 'DELETE';

  if (!data) {
    return request(url, options, accessor);
  }

  return request(url, setJsonBody(data, options), accessor);
}

export function setJsonBody<Data extends object>(data: Data, options: RequestInit): RequestInit {
  return {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
}
