export enum FilterOperator {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const CATEGORY_BOOKMARKS = 'BOOKMARKS';
export const CATEGORY_SHARED = 'SHARED';
export const CATEGORY_ALL = 'ALL';
export const CATEGORY_RECENT = 'RECENT';
export const CATEGORY_TUTORIAL = 'TUTORIAL';

export interface FeedQuery {
  // sorting
  sortOrder?: SortOrder[];
  sortFields?: (keyof FeedContentItem)[];

  // filtering
  filterFields?: (keyof FeedContentItem)[];
  filterOperators?: FilterOperator[];
  filterValues?: string[];

  // searching
  query?: string;

  // pagination
  page?: number; // 0 based
  pageSize?: number;
}

export interface FeedContentItem {
  id: string;
  title?: string;
  subTitle?: string;
  image?: string;
  url: string;
  timestamp: string;
  formattedDateTime: string;
  applicationId: string;
  category: string;
  body: string;
  video?: string;
  metadata: {
    ANALYZE_CONTENT_TYPE?: 'PROJECT' | 'DATASET' | 'OFFER' | 'REPORT';
    CLASSIC_CONTENT_TYPE?: 'PROJECT' | 'DATASET' | 'DASHBOARD';
  };
}
export interface FeedResponse {
  page: number;
  itemCount: number;
  offset: number;
  items: FeedContentItem[];
  hasMoreContent: boolean;
}

export enum FeedTimePeriod {
  JUST_NOW = 'JUST_NOW',
  A_FEW_MOMENTS_AGO = 'MOMENTS_AGO',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  MONTH_YEAR = 'MONTH_YEAR'
}
