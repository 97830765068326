import { makeStyles, tokens } from '@mtabnpd/mtab-design-system';

export const useSetupFormLabelStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: tokens.spacingVerticalS
  }
});
