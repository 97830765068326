import { Application } from 'types';
import { EntityAttributeValue } from './attributes';
import { PasswordPolicy } from './password';

export enum UserSubscriptionType {
  MANAGED = 'MANAGED',
  TRIAL = 'TRIAL',
  SUBSCRIBED = 'SUBSCRIBED'
}

export enum UserSubscriptionStatus {
  TRIALING = 'TRIALING',
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  PAST_DUE = 'PAST_DUE',
  CANCELED = 'CANCELED',
  UNPAID = 'UNPAID'
}

export enum UserAccountProvider {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export interface UserContext {
  userId: string;
  tenantId: string;
  identityId: string;
}

export enum SubscriptionCategory {
  INSIGHT_CLOUD_SUBSCRIPTION = 'INSIGHT_CLOUD_SUBSCRIPTION',
  MTAB_PLUS = 'MTAB_PLUS'
}

export enum AppPromptKey {
  // not implemented in ui
  APP_SWITCHER = 'platform.application-switcher',
  MTAB_PLUS = 'platform.mtab-plus-popup'
}

export interface SubscriptionTimeRemaining {
  isExpired: boolean;

  endsToday?: boolean;
  value?: number;
  unit?: 'days' | 'hours';
  expiresAtTime?: string;
}

export interface UserSubscription {
  productId: string;
  label: string;
  subscriptionId: string;
  subscriptionStart: string;
  subscriptionEnd: string;
  category: SubscriptionCategory;
  status: UserSubscriptionStatus;
  cost: number;
  timeRemaining?: SubscriptionTimeRemaining;
}

export interface UserSetup {
  acceptedTermsAndConditions: boolean;
  tenantIndustries: EntityAttributeValue[];
  userIndustries: EntityAttributeValue[];
  masterSubscriptionAgreementUrl: string;
  otherIndustry?: string;
}

export interface UserProfile {
  userId: string;
  displayName: string;
  email: string;
  tenantId: string | null;
  tenantLabel: string;
  tenantOptions: Tenant[];
  userApplications: Application[];
  impersonation: ImpersonationInfo;
  paymentsUrl: string;
  userSetup: UserSetup;

  // Insight cloud subscription
  subscription: {
    type: UserSubscriptionType;
    detail?: UserSubscription;
  };

  mtabPlusSubscription?: UserSubscription;

  prompts: AppPromptKey[];
  selfDeleteAccount: {
    canDelete: boolean;
    reason: string;
  };
  mtabPlus: boolean;
}

export interface UserSettings {
  username: string;
  displayName: string;
  firstName: string;
  lastName: string;
  primaryEmail: string;
  accountProvider: UserAccountProvider;
  attributes: EntityAttributeValue[];
  otherIndustry?: string;

  passwordPolicy: PasswordPolicy;
  notifications: UserNotificationPreferences;
}

export interface Tenant {
  tenantId: string;
  tenantLabel: string;
  displayName: string;
  userEmail: string;
  userId: string;
}

export interface ImpersonationInfo {
  canImpersonate: boolean;
  impersonating: boolean;
  returnProfile?: Tenant;
}

export interface UserNotificationPreferences {
  subscribeToMarketingEmails: boolean;
  subscribeToStudyEmails: boolean;
}

export interface Benefit {
  benefitId: string;
  benefitItemId: string;
  title: string;
  description: string;
  imageUrl: string;
  benefitUrl: string;
  promoCode?: string;
  redemption?: string;
}
