import {
  isThisMinute,
  isThisHour,
  isToday,
  isYesterday,
  format, isThisMonth, isThisWeek
} from 'date-fns';

import { FeedTimePeriod } from 'types/feed';

import { getPreferredLocale } from './locale';

const preferredLocale = getPreferredLocale();

const defaultDateTimeFormatter = Intl.DateTimeFormat(
  preferredLocale,
  {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }
);
const defaultDateFormatter = Intl.DateTimeFormat(
  preferredLocale,
  {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  }
);
const monthYearFormatter = Intl.DateTimeFormat(
  preferredLocale,
  {
    year: 'numeric',
    month: 'long'
  }
);

export function hourMinuteFormatter(date: Date): string {
  return format(date, 'HH:mm');
}

export function defaultContentDateFormatter(date: Date): string {
  return format(date, 'dd LLLL yyyy');
}

export function formatDateTime(date: Date): string;
export function formatDateTime(date: string): string;
export function formatDateTime(date: any): string {
  return defaultDateTimeFormatter.format(typeof date === 'string' ? new Date(date) : date);
}

export function formatDate(date: Date): string;
export function formatDate(date: string): string;
export function formatDate(date: any): string {
  return defaultDateFormatter.format(typeof date === 'string' ? new Date(date) : date);
}

export function formatMonthYear(date: Date): string;
export function formatMonthYear(date: string): string;
export function formatMonthYear(date: any): string {
  return monthYearFormatter.format(typeof date === 'string' ? new Date(date) : date);
}

export function getFeedTimePeriod(timestamp: Date | string): FeedTimePeriod | string {
  const date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp;

  if (isThisMinute(date)) {
    return FeedTimePeriod.JUST_NOW;
  }

  if (isThisHour(date)) {
    return FeedTimePeriod.A_FEW_MOMENTS_AGO;
  }

  if (isToday(date)) {
    return FeedTimePeriod.TODAY;
  }

  if (isYesterday(date)) {
    return FeedTimePeriod.YESTERDAY;
  }

  if (isThisWeek(date)) {
    return FeedTimePeriod.THIS_WEEK;
  }

  if (isThisMonth(date)) {
    return FeedTimePeriod.THIS_MONTH;
  }

  return formatMonthYear(date);
}

export function relativeDateTimeFormatter(date: Date): string {
  if (isThisMinute(date)) {
    return 'Just now';
  }

  if (isThisHour(date)) {
    return `A few moments ago at ${hourMinuteFormatter(date)}`;
  }

  if (isToday(date)) {
    return `Today at ${hourMinuteFormatter(date)}`;
  }

  if (isYesterday(date)) {
    return `Yesterday at ${hourMinuteFormatter(date)}`;
  }

  return defaultContentDateFormatter(date);
}
