import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs';
import { isBefore } from 'date-fns';

import {
  CATEGORY_BOOKMARKS, FeedContentItem, FeedQuery, FeedResponse, FilterOperator, SortOrder
} from 'types/feed';
import { getFeedTimePeriod } from 'utilities/dateTime';

import { FeedQueryRequest } from './feed';

const DELIMITER = ',';

export const serializeQueryArgs: SerializeQueryArgs<FeedQueryRequest> = ({
  queryArgs: { page, ...queryArgs },
  endpointDefinition,
  endpointName
}) => defaultSerializeQueryArgs({
  endpointName,
  endpointDefinition,
  queryArgs
});

function parseFieldArray(values: string[]) {
  return values
    .map((value) => value.toUpperCase())
    .join(DELIMITER);
}

function createParamTransformer(searchParams: URLSearchParams) {
  return (key: string, value: number | boolean | string | string[]) => {
    if (Array.isArray(value)) {
      searchParams.append(key, parseFieldArray(value));

      return;
    }

    searchParams.append(key, String(value).toUpperCase());
  };
}

export function hasMoreContent(feedResponse: FeedResponse) {
  return (feedResponse.offset + feedResponse.items.length) < feedResponse.itemCount;
}

export function mergeFeedItems(
  currentItems: FeedContentItem[],
  newItems: FeedContentItem[]
): FeedContentItem[] {
  const itemCache = new Map(currentItems.map((item) => [item.id, item]));
  const itemsToAdd = newItems.filter((item) => !itemCache.has(item.id));

  if (itemsToAdd.length) {
    const [first] = itemsToAdd;
    const itemDate = new Date(first.timestamp);
    const insertIndex = currentItems.slice(0)
      .reverse()
      .findIndex((currentItem) => (
        isBefore(itemDate, new Date(currentItem.timestamp))
      ));

    if (insertIndex > -1) {
      currentItems.splice(currentItems.length - insertIndex, 0, ...itemsToAdd);
    } else {
      currentItems.push(...itemsToAdd);
    }
  }

  return currentItems;
}

export function isBookmarksRequest(query?: FeedQueryRequest) {
  if (!query) {
    return false;
  }

  return query.filterFields?.includes('category')
    && query.filterValues?.includes(CATEGORY_BOOKMARKS);
}

export function createFeedQueryParameters(query?: FeedQueryRequest) {
  if (!query) {
    return '';
  }

  const searchParams = new URLSearchParams();
  const transformer = createParamTransformer(searchParams);

  Object.entries(query)
    .filter(([key, value]) => key !== 'id' && value != null)
    .forEach(([key, value]) => transformer(key, value));

  return `?${searchParams.toString()}`;
}

export function uniqueContentItems(items: FeedContentItem[]) {
  const contentItemIds = new Set<string>(items.map(({ id }) => id));

  return items.reduce<FeedContentItem[]>((acc, item) => {
    if (contentItemIds.has(item.id)) {
      acc.push(item);
      contentItemIds.delete(item.id);
    }

    return acc;
  }, []);
}

export const groupByTimePeriod = groupContentTimes(
  ({ timestamp }) => getFeedTimePeriod(timestamp)
);

function groupContentTimes<Prop extends string | number | symbol>(
  accessor: (item: FeedContentItem) => Prop
) {
  return function groupBy(items: FeedContentItem[]): Record<Prop, FeedContentItem[]> {
    return items.reduce(
      (acc, item) => {
        const key = accessor(item);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (acc[key] !== undefined) {
          acc[key].push(item);
        } else {
          acc[key] = [item];
        }

        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as Record<Prop, FeedContentItem[]>
    );
  };
}

export function createQueryFromSearchParams(params: URLSearchParams): FeedQuery {
  const feedQuery: FeedQuery = {};

  params.forEach((value, key) => {
    switch (key) {
      case 'query': {
        feedQuery.query = value;
        break;
      }
      case 'filterValues': {
        feedQuery.filterValues = value.split(',');
        break;
      }
      case 'filterOperators': {
        feedQuery.filterOperators = value.split(',') as FilterOperator[];
        break;
      }
      case 'filterFields': {
        feedQuery.filterFields = value.split(',') as (keyof FeedContentItem)[];
        break;
      }
      case 'sortOrder': {
        feedQuery.sortOrder = value.split(',') as SortOrder[];
        break;
      }
      case 'sortFields': {
        feedQuery.sortFields = value.split(',') as (keyof FeedContentItem)[];
        break;
      }
      default: {
        console.warn(`Unexpected parameter: ${key}`);
      }
    }
  });

  return feedQuery;
}
