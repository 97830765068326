import React from 'react';
import { ErrorBoundary } from '@mtabnpd/mtab-platform-core';

import { CookieConsent } from 'components/cookieConsent';
import { MTabPlusPrompt } from 'components/mTabPlusPrompt';

import { AppContent } from './AppContent';

import './App.scss';

function App() {
  return (
    <ErrorBoundary goToLabel="Home" goToUrl="">
      <CookieConsent />
      <MTabPlusPrompt />
      <AppContent />
    </ErrorBoundary>
  );
}

export default App;
