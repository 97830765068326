import { useState } from 'react';
import { Modal } from '@mtabnpd/mtab-platform-core';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';
import { useGetUserProfile, useSaveLegalDisclaimerConsentMutation } from 'services/api/user';

function Disclaimer() {
  return (
    <div>
      To continue, you must agree to mTab's&nbsp;
      <a href="https://www.mtab.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    &nbsp;and&nbsp;
      <a href="https://www.mtab.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
      .
    </div>
  );
}

export function LegalDisclaimerConsent() {
  const { authState } = useAuthProvider();
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const { legalDisclaimerAccepted, userId, isLoading: userIsLoading } = useGetUserProfile(undefined, {
    selectFromResult: ({ data: profile, isLoading }) => ({
      isLoading,
      userId: profile?.userId,
      legalDisclaimerAccepted: profile?.userSetup.acceptedTermsAndConditions
    })
  });
  const [acceptLegal] = useSaveLegalDisclaimerConsentMutation();
  const userLoggedIn = authState && authState.isAuthenticated && userId;

  if (!userLoggedIn || userIsLoading || legalDisclaimerAccepted) {
    return null;
  }

  return (
    <Modal
      title="Terms and conditions"
      okText="Accept"
      maskClosable={false}
      cancelButtonProps={{ hidden: true }}
      visible={modalIsOpen}
      closable={false}
      onOk={() => {
        setModalIsOpen(false);
        acceptLegal();
      }}
    >
      <Disclaimer />
    </Modal>
  );
}
