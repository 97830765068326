import {
  differenceInDays,
  differenceInHours,
  isPast,
  isToday
} from 'date-fns';
import {
  SubscriptionTimeRemaining,
  UserSubscription,
  UserSubscriptionStatus
} from 'types/user';

import { hourMinuteFormatter } from 'utilities/dateTime';

export function subscriptionIsExpired(subscription: UserSubscription) {
  const { status, subscriptionEnd } = subscription;

  if (
    status === UserSubscriptionStatus.CANCELED
    || status === UserSubscriptionStatus.UNPAID
    || status === UserSubscriptionStatus.PAST_DUE
  ) {
    return true;
  }

  return isPast(new Date(subscriptionEnd));
}

export function getTimeRemaining(
  subscription: UserSubscription
): SubscriptionTimeRemaining | undefined {
  const { subscriptionEnd } = subscription;
  const subscriptionEndDate = new Date(subscriptionEnd);
  const comparatorDate = new Date();

  const endsToday = isToday(subscriptionEndDate);
  const isExpired = subscriptionIsExpired(subscription);
  const diffHours = differenceInHours(subscriptionEndDate, comparatorDate);

  if (isExpired || endsToday) {
    return {
      isExpired,
      endsToday,
      expiresAtTime: endsToday ? hourMinuteFormatter(subscriptionEndDate) : undefined,
      value: Math.max(0, diffHours + 1),
      unit: 'hours'
    };
  }

  if (diffHours < 24) {
    return {
      endsToday,
      isExpired,
      value: diffHours + 1,
      unit: 'hours'
    };
  }

  return {
    value: differenceInDays(subscriptionEndDate, comparatorDate) + 1,
    unit: 'days',
    endsToday,
    isExpired
  };
}
