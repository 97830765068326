import { FontSize, Text } from 'components/text';

export function NotOriginalUserError() {
  return (
    <div>
      <Text size={FontSize.LARGE}>This page is not available while impersonating as another user.</Text>
      <Text size={FontSize.LARGE}>Please return to your original account and try again.</Text>
    </div>
  );
}
