import { intl } from 'lib/i18n';

export const CONTACT_URL = 'https://www.mtab.com/contact';
export const USER_GUIDE_URL = 'https://manuals.mtab.com';

export const TUTORIALS_LABEL = intl.formatMessage({
  defaultMessage: 'Tutorials',
  description: 'Tutorials menu item label'
});

export const USER_GUIDE_LABEL = intl.formatMessage({
  defaultMessage: 'User Guide',
  description: 'User Guide menu item label'
});

export const CONTACT_LABEL = intl.formatMessage({
  defaultMessage: 'Contact',
  description: 'Contact menu item label'
});
