import { useEffect, useState } from 'react';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';

import { Text } from 'components/text';

interface LoginErrorProps {
  error: Error;
}

const USER_CREATION_ERROR = /^user creation was disabled/i;

export function LoginError({ error }: LoginErrorProps) {
  const { oktaAuth } = useAuthProvider();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (USER_CREATION_ERROR.test(error.message)) {
      oktaAuth.signInWithRedirect();

      return;
    }

    setMessage(error.message);
  }, [error, oktaAuth]);

  return (
    <Text>
      {message}
    </Text>
  );
}
