import { makeStaticStyles, tokens } from '@mtabnpd/mtab-design-system';

export const useAppContentStaticStyles = makeStaticStyles({
  '.mtab-app-header .mtab-app-header__search--error': {
    color: tokens.colorStatusDangerForeground1,
    borderColor: tokens.colorStatusDangerForeground1,
    outlineColor: tokens.colorStatusDangerForeground1
  },
  '.mtab-app-header .mtab-app-header__search--error::after': {
    borderBottomWidth: '0px !important'
  }
});
