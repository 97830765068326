import { setDisclaimer, AppDisclaimerType, setMetaData } from 'reducers/app';
import { getIndustries } from 'services/app';
import { AppThunk } from 'store';

export const acceptCookieConsent = () => setDisclaimer({
  type: AppDisclaimerType.COOKIES,
  accepted: true
});

export function getIndustryValues(): AppThunk {
  return function (dispatch) {
    return getIndustries()
      .then((result) => {
        dispatch(setMetaData({ attributes: result }));
      });
  };
}
