import { Routes, Route, Navigate } from 'react-router-dom';
import {
  LoginCallback,
  UserEnvironmentGuard,
  ErrorHandler,
  toAbsoluteUrl
} from '@mtabnpd/mtab-client-auth-js';
import { Surface } from '@mtabnpd/mtab-design-system';

import { LegalDisclaimerConsent } from 'components/legalDisclaimerConsent';
import { AUTH_CALLBACK_PATH } from 'constants/auth';
import {
  ROUTE_DEFAULT,
  ROUTE_ERROR,
  ROUTE_LOGIN_SSO,
  ROUTE_REDIRECT_QUERY_PARAM,
  ROUTE_REDIRECT_REGION,
  RouteErrorType,
  ROUTE_LOGIN
} from 'constants/routes';
import { useFullStory } from 'hooks/fullStory';
import { useFeatureFlag } from 'lib/features';
import { useSupportWidget } from 'lib/integrations';
import { Outlet } from 'routes';
import { UserSetupInterceptor } from 'routes/userSetupInterceptor';
import { Error } from 'routes/error';
import Login, { LoginError } from 'routes/login';
import { LoginWithIDP } from 'routes/loginWithIDP';

import classNames from 'classnames';
import { withBasename } from 'utilities/url';
import { AppHeader } from './AppHeader';
import { UserRegionRedirect } from './routes/redirect';
import { useAppContentStaticStyles } from './AppContent.styles';

const getReloadLocation = () => toAbsoluteUrl(withBasename(ROUTE_DEFAULT), window.location.origin);

function Content() {
  return (
    <div className="App">
      <AppHeader />
      <div className={classNames('App__container')}>
        <Outlet />
      </div>
    </div>
  );
}
export function AppContent() {
  useAppContentStaticStyles();
  const { flags: { ENABLE_USER_SETUP_INTERCEPTOR, ENABLE_ZENDESK_INTEGRATION } } = useFeatureFlag();

  useSupportWidget(ENABLE_ZENDESK_INTEGRATION ? 'zendesk' : undefined);
  useFullStory();

  return (
    <Surface
      className="App"
      appearance="alternate"
    >
      {!ENABLE_USER_SETUP_INTERCEPTOR && (
        <LegalDisclaimerConsent />
      )}
      <Routes>
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route
          path={AUTH_CALLBACK_PATH}
          element={(
            <LoginCallback
              errorComponent={({ error }) => (
                <ErrorHandler
                  error={error}
                  reloadLocation={getReloadLocation()}
                  errorComponent={LoginError}
                />
              )}
            />
          )}
        />
        <Route path={`${ROUTE_LOGIN_SSO}/:idp`} element={<LoginWithIDP />} />
        <Route path={`${ROUTE_ERROR}/:errorType`} element={<Error />} />
        <Route path={`${ROUTE_REDIRECT_REGION}`} element={<UserRegionRedirect />} />

        <Route
          element={(
            <UserEnvironmentGuard
              onIntercept={({ redirectUri }, navigate) => {
                navigate(`${ROUTE_REDIRECT_REGION}?${ROUTE_REDIRECT_QUERY_PARAM}=${redirectUri}`);
              }}
            />
         )}
        >
          {ENABLE_USER_SETUP_INTERCEPTOR ? (
            <Route element={<UserSetupInterceptor />}>
              <Route path="*" element={<Content />} />
            </Route>
          ) : (
            <Route path="*" element={<Content />} />
          )}
        </Route>

        <Route path="*" element={<Navigate to={`${ROUTE_ERROR}/${RouteErrorType.NOT_FOUND}`} />} />
      </Routes>
    </Surface>
  );
}
