import {
  Button,
  mergeClasses,
  Surface,
  Flex
} from '@mtabnpd/mtab-design-system';

import { acceptCookieConsent } from 'actions/app';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { AppDisclaimerType, selectDisclaimers } from 'reducers/app';

import { DISCLAIMER_TEXT } from './CookieConsent.consts';
import { useCookieConsentStyles } from './CookieConsent.styles';

export function CookieConsent() {
  const disclaimers = useAppSelector(selectDisclaimers);
  const dispatch = useAppDispatch();
  const styles = useCookieConsentStyles();

  const accept = () => dispatch(acceptCookieConsent());

  if (disclaimers[AppDisclaimerType.COOKIES]) {
    return null;
  }

  return (
    <Surface
      component={Flex}
      className={mergeClasses(styles.root, 'c-cookie-consent')}
      alignItems="center"
      justifyContent="center"
    >
      {DISCLAIMER_TEXT}
      &nbsp;
      <Button
        className={mergeClasses(styles.button, 'c-cookie-consent__button')}
        onClick={accept}
      >
        OK
      </Button>
    </Surface>
  );
}
