import { PUBLIC_URL } from 'constants/app';

export function withBasename(path: string): string {
  if (path.startsWith('/')) {
    return `${PUBLIC_URL}${path}`;
  }

  return `${PUBLIC_URL}/${path}`;
}

export function removeBaseName(path: string): string {
  if (path.startsWith(PUBLIC_URL)) {
    return path.slice(PUBLIC_URL.length);
  }

  return path;
}
