import { NotificationConfig } from '@mtabnpd/mtab-platform-core';

import { Notifications } from 'lib/notifications';
import { AppThunk } from 'store';

export const successNotification = ({ message, url }: NotificationConfig): AppThunk => () => {
  Notifications.success({ message, url });
};

export const errorNotification = ({ message, description }: NotificationConfig): AppThunk => () => {
  Notifications.error({
    message,
    description,
    duration: 10
  });
};
