import { AuthOptions } from '@mtabnpd/mtab-client-auth-js';

import {
  AUTH_CALLBACK_PATH,
  AUTH_CLIENT_ID,
  AUTH_ISSUER,
  AUTH_SERVER_PATH,
  AUTH_SCOPES
} from 'constants/auth';
import { ROUTE_LOGIN, ROUTE_DEFAULT } from 'constants/routes';
import { getFeatureFlags } from 'lib/features';
import { userApi } from 'services/api/user';
import { store } from 'store';
import { UserContext } from 'types/user';
import { withBasename } from 'utilities/url';

const { ENABLE_USER_PROFILE_PULSE } = getFeatureFlags();

const { host } = window.location;
const { protocol } = window.location;

const userProfileSelector = userApi.endpoints.getUserProfile.select();

export const config: AuthOptions = {
  clientId: AUTH_CLIENT_ID,
  issuer: `${AUTH_ISSUER}${AUTH_SERVER_PATH}`,
  redirectUri: `${protocol}//${host}${withBasename(AUTH_CALLBACK_PATH)}`,
  scopes: AUTH_SCOPES.split(/\s+/),
  postLogoutRedirectUri: `${protocol}//${host}${withBasename(ROUTE_DEFAULT)}`,
  onAuthRequired: (authInstance) => {
    const { location } = window;

    if (location.pathname.startsWith(withBasename(ROUTE_LOGIN))) {
      return;
    }

    return authInstance.signInWithRedirect();
  },
  pulse: {
    enabled: ENABLE_USER_PROFILE_PULSE,
    query: {
      url: withBasename('/api/v1/auth/user/context'),
      responseTransformer: (result: UserContext) => {
        const { data: currentProfile } = userProfileSelector(store.getState());
        return result.userId === currentProfile?.userId;
      }
    }
  }
};
