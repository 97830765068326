import { HTMLProps, MouseEvent } from 'react';
import classNames from 'classnames';

import { Text } from 'components/text';
import { EntityAttributeValue } from 'types';

import './InterestTile.scss';

interface InterestTileProps extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  interest: EntityAttributeValue;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (
    interest: EntityAttributeValue,
    e: MouseEvent<HTMLElement>
  ) => void;
}

export function InterestTile({
  interest,
  onClick,
  disabled,
  selected
}: InterestTileProps) {
  const { value, iconUrl } = interest;
  const classes = classNames(
    'c-user-interest-tile',
    disabled && 'c-user-interest-tile--disabled',
    selected && 'c-user-interest-tile--selected',
    onClick && 'c-user-interest-tile--interactive'
  );
  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      className={classes}
      onClick={(e) => !disabled && onClick?.(interest, e)}
    >
      <img
        className="c-user-interest-tile__image"
        src={iconUrl}
        alt={value}
        draggable={false}
      />
      <Text header>{value}</Text>
    </div>
  );
}
