import { useEffect, useState } from 'react';
import { intl } from 'lib/i18n';
import { Button, Modal } from '@mtabnpd/mtab-platform-core';

import { ApplicationType } from 'types';
import { AppPromptKey } from 'types/user';
import { MtabPlusDiscountPrompt, MTabPlusTextLogo } from 'resources/assets/images';
import { useClearApplicationPromptMutation, useGetUserProfile } from 'services/api/user';
import { Text, FontSize } from 'components/text';
import { SubscriptionTimeRemainingUnit } from 'components/subscriptionTimeRemainingUnit';

import './MTabPlusPrompt.scss';
import { FormattedMessage } from 'react-intl';
import { useShouldShowPrompt } from './MTabPlusPrompt.hooks';

const MODAL_WIDTH = 727;
const SAVE_UP_TO_PERCENT = 30;
const PERCENT_TEXT_SIZE = 38;
const MARKETPLACE_TEXT_SIZE = 34;

export function MTabPlusPrompt() {
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const { data: profile } = useGetUserProfile();
  const [clearApplicationPrompt] = useClearApplicationPromptMutation();
  const { userApplications = [], mtabPlusSubscription } = profile ?? {};
  const showPrompt = useShouldShowPrompt(profile);
  const remainingTrialTime = mtabPlusSubscription?.timeRemaining;
  const marketplaceApp = userApplications.find(
    (app) => app.applicationId === ApplicationType.MARKETPLACE
  );

  const acceptPrompt = async () => {
    if (showPrompt) {
      return clearApplicationPrompt(AppPromptKey.MTAB_PLUS).then(() => {
        setIsPromptVisible(false);
      });
    }
  };

  useEffect(() => {
    setIsPromptVisible(showPrompt);
  }, [showPrompt]);

  const bannerAltText = intl.formatMessage({
    defaultMessage: 'mTab+ discount banner',
    description: 'Alt text for mTab+ banner image'
  });

  return (
    <Modal
      className="c-mtab-plus-prompt"
      width={MODAL_WIDTH}
      visible={isPromptVisible}
      footer={null}
      onCancel={acceptPrompt}
      maskClosable
      closable
      centered
    >
      <div className="c-mtab-plus-prompt__details">
        <div className="c-mtab-plus-prompt__details-welcome">
          <Text header size={FontSize.HUGE}>
            <FormattedMessage
              defaultMessage="Welcome to"
              description="Welcome to text"
            />
          </Text>
          <MTabPlusTextLogo />
          <div className="c-mtab-plus-prompt__details-welcome-text-block">
            <Text header size={FontSize.HUGE}>
              <FormattedMessage
                defaultMessage="Save up to"
                description="Save up to text"
              />
              &nbsp;
            </Text>
            <Text header size={FontSize.HUGE} style={{ fontSize: PERCENT_TEXT_SIZE }}>
              {SAVE_UP_TO_PERCENT}
              %&nbsp;
            </Text>
            <Text header size={FontSize.HUGE}>
              <FormattedMessage
                defaultMessage="on"
                description="on preposition"
              />
            </Text>
          </div>
          <div className="c-mtab-plus-prompt__details-welcome-text-block">
            <Text header size={FontSize.HUGE} style={{ fontSize: MARKETPLACE_TEXT_SIZE }}>
              <FormattedMessage
                defaultMessage="Marketplace"
                description="Marketplace label"
              />
              &nbsp;
            </Text>
            <Text header size={FontSize.HUGE}>
              <FormattedMessage
                defaultMessage="orders."
                description="orders text"
              />
            </Text>
          </div>
        </div>
        <div className="c-mtab-plus-prompt__details-explore">
          {remainingTrialTime && (
            <Text header size={FontSize.LARGE}>
              <FormattedMessage
                defaultMessage="Your trial ends in"
                description="Trial ends in text"
              />
              &nbsp;
              <SubscriptionTimeRemainingUnit {...remainingTrialTime} />
            </Text>
          )}
          {marketplaceApp && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <a href={marketplaceApp.url}>
              <Button
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  acceptPrompt().then(() => {
                    window.location.assign(marketplaceApp.url);
                  });
                }}
              >
                <FormattedMessage
                  defaultMessage="Explore marketplace"
                  description="Explore marketplace link button"
                />
              </Button>
            </a>
          )}
        </div>
      </div>
      <div className="c-mtab-plus-prompt__banner">
        <img src={MtabPlusDiscountPrompt} alt={bannerAltText} />
      </div>
    </Modal>
  );
}
