export const ROUTE_DEFAULT = '/';

export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGIN_SSO = `${ROUTE_LOGIN}/sso`;
export const ROUTE_FEATURED = '/featured';
export const ROUTE_IMPERSONATION = '/impersonate';
export const ROUTE_TENANT_SWITCHER = '/switch-tenant';
export const ROUTE_ERROR = '/error';
export const ROUTE_FEED_ALL = '/all';
export const ROUTE_FEED_SHARED = '/shared';
export const ROUTE_FEED_RECENT = '/recent';
export const ROUTE_FEED_TUTORIALS = '/tutorials';
export const ROUTE_FEED_FAVORITES = '/favorites';
export const ROUTE_FEED_SEARCH = '/search';
export const ROUTE_MTAB_PLUS_BENEFITS = '/mtab-plus-benefits';

export const ROUTE_REDIRECT = '/redirect';
export const ROUTE_REDIRECT_REGION = `${ROUTE_REDIRECT}/region`;
export const ROUTE_REDIRECT_QUERY_PARAM = 'redirect';

export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_USER = `${ROUTE_SETTINGS}/user`;

export enum RouteErrorType {
  NOT_FOUND = 'not-found',
  NOT_ORIGINAL_USER = 'not-original-user',
  GENERIC_ERROR = 'unspecified'
}
