import { FeedContentItem, FeedQuery } from 'types/feed';

export enum FilterUpdateStrategy {
  REPLACE,
  MERGE
}

export interface FeedQueryState {
  currentQuery: FeedQuery | undefined;
}

export interface ChangePageActionPayload {
  destination: number;
  relative?: boolean;
}

export type SetFiltersActionPayload = {
  values: Partial<Omit<FeedContentItem, 'metadata'>>;
  strategy?: FilterUpdateStrategy;
  resetPage?: boolean;
};
