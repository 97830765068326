import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
