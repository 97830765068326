import { RootState } from '../store';
import { applyDefaultValues } from './migration';

const STORAGE_KEY = 'platform-home-portal-redux-state';
const STORAGE_VERSION = 1;

export function loadState() {
  try {
    const serialized = localStorage.getItem(STORAGE_KEY);

    if (serialized === null) {
      return undefined;
    }

    const rehydrated: { version: number; state: Partial<RootState> } = { version: 0, state: {} };
    const deserialized = JSON.parse(serialized);

    rehydrated.version = deserialized.version ?? 0;
    rehydrated.state = deserialized.state ?? deserialized;

    return applyDefaultValues(rehydrated.state);
  } catch (err) {
    throw new Error('Failed to load state');
  }
}

export const saveState = (state: Partial<RootState>) => {
  try {
    const serialized = JSON.stringify({
      version: STORAGE_VERSION,
      state
    });

    localStorage.setItem(STORAGE_KEY, serialized);
  } catch (err) {
    throw new Error('Failed to save state');
  }
};
