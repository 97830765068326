import { useIntl } from 'react-intl';
import { createComparator, Radio } from '@mtabnpd/mtab-platform-core';

import { FontSize, Text } from 'components/text';
import { ANTD_SORT_ORDER_ASCENDING, ANTD_SORT_ORDER_NO_UNSORTED_ORDER } from 'constants/app';
import { Tenant } from 'types/user';

interface OnSelection {
  (id: Tenant): void;
}

const renderTitle = (label: string) => <Text size={FontSize.LARGE} header title={label} ellipsis>{label}</Text>;
const renderText = (label: string) => <Text title={label} ellipsis ellipsisTooltip={label}>{label}</Text>;

export function useColumns(
  onSelection: OnSelection,
  selectedUserId: string | undefined
) {
  const intl = useIntl();

  /* eslint-disable sonarjs/no-duplicate-string */
  const accountTitle = intl.formatMessage({ defaultMessage: 'Account', description: 'Column title' });
  const nameTitle = intl.formatMessage({ defaultMessage: 'Name', description: 'Column title' });
  const usernameTitle = intl.formatMessage({ defaultMessage: 'Username', description: 'Column title' });
  /* eslint-enable sonarjs/no-duplicate-string */

  return [
    {
      key: 'tenantLabel',
      dataIndex: 'tenantLabel',

      title: () => renderTitle(accountTitle),

      defaultSortOrder: ANTD_SORT_ORDER_ASCENDING,
      sortDirections: ANTD_SORT_ORDER_NO_UNSORTED_ORDER,
      sorter: createComparator<Tenant>(({ tenantLabel }) => tenantLabel),

      render: (value: string, record: Tenant) => (
        <Radio checked={record.userId === selectedUserId} onChange={() => onSelection(record)}>
          {renderText(value)}
        </Radio>
      )
    },
    {
      key: 'displayName',
      dataIndex: 'displayName',
      title: () => renderTitle(nameTitle),
      sortDirections: ANTD_SORT_ORDER_NO_UNSORTED_ORDER,
      sorter: createComparator<Tenant>(({ displayName }) => displayName),
      render: renderText
    },
    {
      key: 'userEmail',
      dataIndex: 'userEmail',
      title: () => renderTitle(usernameTitle),
      sortDirections: ANTD_SORT_ORDER_NO_UNSORTED_ORDER,
      sorter: createComparator<Tenant>(({ userEmail }) => userEmail),
      render: renderText
    }
  ] as any;
}
