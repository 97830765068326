import { Outlet, useNavigate } from 'react-router-dom';

import { ROUTE_ERROR, RouteErrorType } from 'constants/routes';
import { useGetUserProfile } from 'services/api/user';
import { useEffect } from 'react';

export function TrueUser() {
  const { data: profile } = useGetUserProfile();
  const navigate = useNavigate();
  const impersonating = profile?.impersonation.impersonating;

  useEffect(() => {
    if (impersonating) {
      navigate(`${ROUTE_ERROR}/${RouteErrorType.NOT_ORIGINAL_USER}`, { replace: true });
    }
  }, [impersonating, navigate]);

  if (impersonating) {
    return null;
  }

  return (
    <Outlet />
  );
}
