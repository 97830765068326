import {
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@mtabnpd/mtab-platform-core';

import { Loader } from 'components/loader';
import { FontSize, Text } from 'components/text';
import { classnames } from 'utilities/classnames';
import { getDefaultModalPosition } from 'utilities/modal';

import { TenantSwitcherWrapperProps } from '../tenantSwitcherWrapper';

import './TenantSwitcherModal.scss';

export interface TenantSwitcherModalProps extends Omit<TenantSwitcherWrapperProps, 'mode'> {
  overlay: boolean;
}

const DEFAULT_MODAL_WIDTH = 800;
const DEFAULT_MODAL_HEIGHT = 700;

export function TenantSwitcherModal({
  visible,
  children,
  overlay,
  loading,
  onAccept,
  onClose
}: TenantSwitcherModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [canSwitch, setCanSwitch] = useState(true);
  const modalContainer = containerRef.current;

  useEffect(() => {
    setCanSwitch(true);
  }, [visible]);

  useEffect(() => {
    if (visible && !loading) {
      // Squashed virtual list fix
      forceUpdate();
    }
  }, [visible, loading]);

  return (
    <>
      <div ref={containerRef} />
      {modalContainer && (
        <Modal
          destroyOnClose
          resizable
          defaultPosition={getDefaultModalPosition(DEFAULT_MODAL_WIDTH, DEFAULT_MODAL_HEIGHT)}
          handleOffset={-5}
          title={(
            <Text header size={FontSize.LARGE}>
              <FormattedMessage defaultMessage="Switch account" description="Modal title" />
            </Text>
          )}
          className="c-tenant-switcher-modal"
          getContainer={() => modalContainer}
          wrapClassName={classnames({ 'c-tenant-switcher-modal__overlay': overlay })}
          visible={visible}
          onOk={() => {
            setCanSwitch(false);
            onAccept();
          }}
          okButtonProps={{ disabled: !canSwitch || loading || !children.props.selectedTenant }}
          onCancel={onClose}
          closable={false}
        >
          {loading || !canSwitch ? <Loader /> : children}
        </Modal>
      )}
    </>
  );
}
