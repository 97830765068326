import React, { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import {
  Flex,
  Typography,
  tokens,
  TypographyType, mergeClasses
} from '@mtabnpd/mtab-design-system';
import { useFormStyles } from './Form.styles';

interface FormProps extends Omit<HTMLProps<HTMLFormElement>, 'title'> {
  title?: ReactNode;
  icon?: ReactNode;
  gap?: string;
}

export function Form({
  className,
  title,
  icon,
  children,
  gap = tokens.spacingVerticalL,
  ...formProps
}: PropsWithChildren<FormProps>) {
  const formStyles = useFormStyles();
  return (
    <form
      className={mergeClasses(formStyles.root, 'c-form', className)}
      {...formProps}
    >
      <Flex
        direction="column"
        gap={gap}
      >
        {title && (
          <Typography
            className="c-form__title-label"
            secondary
            type={TypographyType.SUBTITLE1}
          >
            <Flex alignItems="center" gap={tokens.spacingHorizontalM}>
              {icon}
              <div className="c-form__title-content">{title}</div>
            </Flex>
          </Typography>
        )}
        {children}
      </Flex>
    </form>
  );
}
