import { useLayoutEffect, useState } from 'react';
import { Router as BaseRouter, BrowserRouterProps } from 'react-router-dom';

import { PUBLIC_URL } from 'constants/app';
import { browserHistory } from 'lib/history';

export function Router(props: BrowserRouterProps) {
  const [state, setState] = useState({
    action: browserHistory.action,
    location: browserHistory.location
  });

  useLayoutEffect(() => browserHistory.listen(setState), []);

  return (
    <BaseRouter
      {...props}
      basename={PUBLIC_URL}
      location={state.location}
      navigationType={state.action}
      navigator={browserHistory}
    />
  );
}
