import { useEffect, useState } from 'react';

import { INDUSTRY_ATTRIBUTE_VALUE_OTHER } from 'constants/app';
import { EntityAttributeValue } from 'types';

import { InterestTile } from './tile';

import './UserInterests.scss';

interface UserInterestsProps {
  interests: EntityAttributeValue[];

  selectedInterests?: string[];
  onSelectionChange?: (selectedInterestIds: string[]) => void;
  showOther?: boolean;
  max?: number;
}

export function UserInterests({
  interests,
  selectedInterests: initialSelectedInterests,
  onSelectionChange,
  max = Infinity,
  showOther
}: UserInterestsProps) {
  const [selectedInterests, setSelectedInterest] = useState(initialSelectedInterests ?? []);

  useEffect(() => {
    setSelectedInterest(selectedInterests);
  }, [interests, selectedInterests]);

  const visibleInterests = showOther ? interests.concat(INDUSTRY_ATTRIBUTE_VALUE_OTHER) : interests;

  return (
    <div className="c-user-interests">
      <div className="c-user-interests__tiles">
        {visibleInterests
          .map((interest) => {
            const isSelected = selectedInterests.includes(interest.valueId);
            const isDisabled = selectedInterests.length >= max;
            return (
              <InterestTile
                key={interest.valueId}
                interest={interest}
                selected={isSelected}
                disabled={!isSelected && isDisabled}
                onClick={(selected) => {
                  setSelectedInterest((current) => {
                    const currentSet = new Set(current);

                    if (currentSet.has(selected.valueId)) {
                      currentSet.delete(selected.valueId);
                    } else {
                      currentSet.add(selected.valueId);
                    }

                    const next = Array.from(currentSet);
                    onSelectionChange?.(next);
                    return Array.from(currentSet);
                  });
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
