import { Spinner } from '@mtabnpd/mtab-design-system';

import './Loader.scss';

export function Loader() {
  return (
    <div className="c-loader">
      <Spinner size="huge" />
    </div>
  );
}
