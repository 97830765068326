import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubscriptionTimeRemaining } from 'types/user';

export function SubscriptionTimeRemainingUnit({
  unit,
  value
}: PropsWithChildren<NonNullable<SubscriptionTimeRemaining>>) {
  if (unit === 'days') {
    return (
      <FormattedMessage
        defaultMessage="{value, plural, one {# day} other {# days}}"
        description="Time unit"
        values={{
          value
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="{value, plural, one {# hour} other {# hours}}"
      description="Time unit"
      values={{
        value
      }}
    />
  );
}
