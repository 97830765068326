import { ReactElement } from 'react';

import { ImpersonationProps } from '../Impersonation';
import { ImpersonationModal } from '../impersonationModal';
import { ImpersonationModalApp } from '../impersonationModalApp';

export interface ImpersonationWrapperProps extends ImpersonationProps {
  applyDisabled: boolean;
  impersonating: boolean;
  children: ReactElement;
}

export function ImpersonationWrapper({
  mode,
  children,
  visible,
  applyDisabled,
  impersonating,
  onApply,
  onClose,
  onClear
} : ImpersonationWrapperProps) {
  const commonProps = {
    onApply,
    onClose,
    applyDisabled,
    impersonating
  };

  if (mode === 'modal') {
    return (
      <ImpersonationModal visible={visible} {...commonProps}>
        {children}
      </ImpersonationModal>
    );
  }

  if (mode === 'modalApp') {
    return (
      <ImpersonationModalApp visible={visible} onClear={onClear} {...commonProps}>
        {children}
      </ImpersonationModalApp>
    );
  }

  throw new Error('Invalid impersonation mode');
}
