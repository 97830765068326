import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { AppState, AppDisclaimerType, AppMetaData } from './app.types';

export const initialState: AppState = {
  disclaimers: {},
  attributes: {}
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDisclaimer: (state, { payload }: PayloadAction<{
      type: AppDisclaimerType;
      accepted: boolean;
    }>) => ({
      ...state,
      disclaimers: {
        ...state.disclaimers,
        [payload.type]: payload.accepted
      }
    }),
    setMetaData: (state, { payload }: PayloadAction<Partial<AppMetaData>>) => {
      const { attributes: payloadAttributes = {} } = payload;
      return {
        ...state,
        ...payload,
        attributes: {
          ...state.attributes,
          ...payloadAttributes
        }
      };
    }
  }
});

const { actions, reducer } = appSlice;

export const selectDisclaimers = (state: RootState) => state.app.disclaimers;
export const selectAttributes = createSelector(
  (state: RootState) => state.app.attributes,
  (_state: RootState, attributeKey?: string) => attributeKey,
  (attributes, key) => {
    if (!key) {
      return Object.values(attributes).flat();
    }

    return attributes[key] ?? [];
  }
);

export const { setDisclaimer, setMetaData } = actions;
export const { name } = appSlice;
export default reducer;
