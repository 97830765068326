import { Middleware } from '@reduxjs/toolkit';

import { errorNotification } from 'actions/notifications';
import { ROUTE_ERROR, RouteErrorType } from 'constants/routes';
import { browserHistory } from 'lib/history';
import { AppDispatch, RootState } from 'store';
import { isRejectedRTQAction } from 'utilities/actions';
import { getErrorLabel } from 'utilities/httpErrorMessages';
import { withBasename } from 'utilities/url';

type QueryErrorMiddleware = Middleware<void, RootState, AppDispatch>;

export const queryErrorMiddleware: QueryErrorMiddleware = ({ dispatch }) => (next) => (action) => {
  // eslint warning not recognizing overloads correctly
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isRejectedRTQAction(action)) {
    const { payload, meta } = action;
    dispatch(errorNotification({
      message: getErrorLabel(payload.status),
      description: payload.data?.message
    }));

    if (meta.arg?.endpointName === 'getUserProfile') {
      browserHistory.push(withBasename(`${ROUTE_ERROR}/${RouteErrorType.GENERIC_ERROR}`));
    }
  }

  return next(action);
};
