import { useMemo } from 'react';
import { useAuthProvider, AuthState, UserClaims as TokenUserClaims } from '@mtabnpd/mtab-client-auth-js';

type UserClaims = {
  id?: string;
  subject?: string;
  mtabUser: boolean;
  superAdmin: boolean;
};

function getUserClaims(authState: AuthState | null): UserClaims {
  if (!authState) {
    return {
      subject: undefined,
      superAdmin: false,
      mtabUser: false
    };
  }

  const {
    superAdmin = false
  } = (authState.accessToken?.claims ?? {}) as TokenUserClaims<UserClaims>;
  const {
    mtabUser = false,
    email,
    sub
  } = (authState.idToken?.claims ?? {}) as TokenUserClaims<UserClaims>;

  return {
    id: sub,
    subject: email,
    superAdmin,
    mtabUser
  };
}

export function useUserClaims() {
  const { authState } = useAuthProvider();

  return useMemo(
    () => getUserClaims(authState),
    [authState]
  );
}
