import { FeedResponse, FeedQuery } from 'types/feed';
import { formatDateTime } from 'utilities/dateTime';
import { ApplicationType } from 'types';

import { api, Tag } from '../api';
import {
  createFeedQueryParameters,
  hasMoreContent, isBookmarksRequest,
  mergeFeedItems,
  serializeQueryArgs
} from './feed.utils';

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_FEED_QUERY: FeedQuery = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE
};

export interface FeedQueryRequest extends FeedQuery {
  id: string;
}

export const feedAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getContentFeed: builder.query<FeedResponse, FeedQueryRequest>({
      query: (feedQuery) => {
        if (isBookmarksRequest(feedQuery)) {
          return `/auth/feed/bookmarks${createFeedQueryParameters({
            ...feedQuery,
            filterFields: undefined,
            filterValues: undefined,
            filterOperators: undefined
          })}`;
        }

        return `/auth/feed/content${createFeedQueryParameters(feedQuery)}`;
      },
      keepUnusedDataFor: 20,
      transformResponse(contentFeed: FeedResponse, _meta, feedQuery) {
        const { pageSize = DEFAULT_PAGE_SIZE, page = 0 } = feedQuery;

        contentFeed.page = page;
        contentFeed.offset = page * pageSize;
        contentFeed.items.forEach((item) => {
          // fix for marketplace application id error
          // https://github.com/mTABNPD/platform-home-portal-client/commit/6a0c2f35ed789c18d46b0579d037558471d15ece
          // remove after 2023.1
          if (item.id === 'a0007763-65c5-4065-a511-35b3db9b8e75') {
            item.applicationId = ApplicationType.MARKETPLACE;
          }

          item.id = `${item.id}-${item.timestamp}`;
          item.formattedDateTime = formatDateTime(item.timestamp);
        });
        contentFeed.hasMoreContent = hasMoreContent(contentFeed);
        return contentFeed;
      },
      serializeQueryArgs,
      forceRefetch: ({ currentArg, previousArg }) => currentArg?.page !== previousArg?.page,
      merge: (currentCache, feedResponse) => {
        currentCache.itemCount = feedResponse.itemCount;
        currentCache.offset = 0;
        currentCache.items = feedResponse.page === 0
          ? feedResponse.items
          : mergeFeedItems(currentCache.items, feedResponse.items);
        currentCache.hasMoreContent = hasMoreContent(feedResponse);
      },
      providesTags: (_result, _error, arg) => [{ type: Tag.FEED, id: arg.id }]
    })
  })
});

export const { useGetContentFeedQuery } = feedAPI;
