import { useEffect } from 'react';

import { useUserClaims } from 'hooks/userContext';
import { interactionTracking } from 'lib/analytics';

export function useFullStory() {
  const { id, subject, mtabUser } = useUserClaims();

  useEffect(() => {
    if (!subject) {
      return;
    }

    interactionTracking?.setUserIdentity({
      userId: id,
      email: subject,
      isMtabUser: mtabUser
    });
  }, [id, subject, mtabUser]);
}
