import { configureStore, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import appReducer, { name as appReducerName } from 'reducers/app';
import feedQueryReducer, { name as feedQueryReducerName } from 'reducers/feedQuery';

import { api } from 'services/api';

import { subscribe } from './persist';

type CreateStoreOptions = {
  preloadedState?: unknown;
  extraMiddleware?: Middleware[];
  subscribe: boolean;
};

export function createStore(options: CreateStoreOptions) {
  const { preloadedState, extraMiddleware = [] } = options;

  const store = configureStore({
    preloadedState,
    middleware: (defaultMiddleware) => defaultMiddleware()
      .concat(api.middleware)
      .concat(extraMiddleware),
    reducer: {
      [api.reducerPath]: api.reducer,
      [appReducerName]: appReducer,
      [feedQueryReducerName]: feedQueryReducer
    }
  });

  if (options.subscribe) {
    subscribe(store, [appReducerName]);
  }

  setupListeners(store.dispatch);

  return store;
}
