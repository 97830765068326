import {
  Field as MtabDesignField,
  FieldProps,
  makeStyles, mergeClasses
} from '@mtabnpd/mtab-design-system';

// // fits most use cases, includes a 12px margin
const MAX_LABEL_WIDTH = '172px';

const useFieldStyles = makeStyles({
  labelTemplate: {
    gridTemplateColumns: `min(33%, ${MAX_LABEL_WIDTH}) 1fr`
  },
  noLabel: {
    paddingLeft: `min(33%, ${MAX_LABEL_WIDTH})`
  }
});

export function Field({ className, ...props }: FieldProps) {
  const fieldStyles = useFieldStyles();
  const orientation = props.orientation ?? 'horizontal';

  function getLabelClasses() {
    if (orientation !== 'horizontal') {
      return [];
    }

    return props.label
      ? [fieldStyles.labelTemplate]
      : [fieldStyles.noLabel];
  }

  return (
    <MtabDesignField
      className={mergeClasses(
        ...getLabelClasses(),
        className
      )}
      orientation="horizontal"
      centerAlignment
      {...props}
    />
  );
}
