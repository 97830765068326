import { Store } from '@reduxjs/toolkit';
import { throttle } from 'utilities/throttle';

import { saveState } from './localStorage';

export function subscribe(store: Store, whiteList: string[]) {
  store.subscribe(throttle(() => {
    const state = store.getState();
    const persisted = whiteList.reduce((result: Record<string, any>, stateKey) => {
      result[stateKey] = state[stateKey];

      return result;
    }, {});

    saveState(persisted);
  }));
}
