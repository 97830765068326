import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';
import { Button } from '@mtabnpd/mtab-platform-core';

import { AppHeader } from 'AppHeader';
import { FontSize, Text } from 'components/text';
import { APP_PLATFORM, INDUSTRY_ATTRIBUTE_VALUE_OTHER } from 'constants/app';
import { useGetUserProfile, useSaveUserSetupMutation } from 'services/api/user';
import { UserSubscriptionType } from 'types/user';

import { LegalDisclaimer } from './legalDisclaimer';
import { UserSetupForm, UserSetupFormState } from './userSetupForm';
import { validateUserSetup, shouldIntercept, hasIndustries } from './UserSetupInterceptor.utils';

import './UserSetupInterceptor.scss';

type UserSetupState = UserSetupFormState & { isValid: boolean };

export function UserSetupInterceptor() {
  const { authState } = useAuthProvider();
  const { userProfile } = useGetUserProfile(undefined, {
    selectFromResult: (result) => ({
      userProfile: result.data,
      hasError: result.isError
    })
  });
  const [setupUser] = useSaveUserSetupMutation();

  const { impersonating } = userProfile?.impersonation ?? {};
  const requiresIndustries = useMemo(() => !hasIndustries(userProfile), [userProfile]);
  const { userId, subscription, userSetup } = userProfile ?? {};
  const isTrialUser = subscription?.type === UserSubscriptionType.TRIAL;
  const acceptedTermsAndConditions = userSetup?.acceptedTermsAndConditions;

  const [acceptedLegal, setAcceptedLegal] = useState(acceptedTermsAndConditions);
  const [userSetupState, setUserSetupState] = useState<UserSetupState>({ isValid: false });
  const [isValid, setIsValid] = useState(false);
  const [proceed, setProceed] = useState(false);

  const isAuthenticated = authState?.isAuthenticated;
  const hasUserContext = userId && isAuthenticated;
  const intercept = shouldIntercept({ userSetup }, { requiresIndustries });

  useEffect(() => {
    setAcceptedLegal(acceptedTermsAndConditions);
    setUserSetupState((current) => ({
      ...current,
      isValid: validateUserSetup(
        current,
        {
          userSubscriptionType: subscription?.type,
          requiresInterests: requiresIndustries
        }
      )
    }));
  }, [acceptedTermsAndConditions, subscription?.type, requiresIndustries]);

  useEffect(() => {
    setIsValid(!!(acceptedLegal && userSetupState.isValid));
  }, [acceptedLegal, userSetupState.isValid]);

  useLayoutEffect(() => {
    if (impersonating) {
      setProceed(true);
      return;
    }

    if (hasUserContext) {
      setProceed(!intercept);
    }
  }, [hasUserContext, impersonating, intercept]);

  // wait for auth state;
  if (!authState || (authState.isAuthenticated && !hasUserContext)) {
    return null;
  }

  if (proceed || !authState.isAuthenticated) {
    return (
      <Outlet />
    );
  }

  return (
    <div className="App-user-interceptor">
      <AppHeader interactive={false} />
      <div className="App-user-setup__container">
        <div className="App-user-setup">
          <header className="App-user-setup__header">
            <Text header size={FontSize.HUGE}>
              <FormattedMessage defaultMessage="Enhance Your mTab Experience" description="call to action" />
            </Text>
          </header>
          <div className="App-user-setup__content">
            <UserSetupForm
              requiredOrgName={isTrialUser}
              requiredInterests={requiresIndustries}
              onChange={(userSetupForm, userSetupFormIsValid) => {
                setUserSetupState({
                  ...userSetupForm,
                  isValid: userSetupFormIsValid && validateUserSetup(
                    userSetupForm,
                    {
                      userSubscriptionType: subscription?.type,
                      requiresInterests: requiresIndustries
                    }
                  )
                });
              }}
            />
            {!acceptedTermsAndConditions && userSetup && (
              <LegalDisclaimer
                msaUrl={userSetup.masterSubscriptionAgreementUrl}
                isAccepted={acceptedLegal}
                onAccept={() => setAcceptedLegal(true)}
                onReject={() => setAcceptedLegal(false)}
              />
            )}
          </div>
          <footer className="App-user-setup__footer">
            <Button
              type="primary"
              disabled={!isValid}
              onClick={() => {
                setupUser({
                  acceptedTermsAndConditions: true,
                  company: userSetupState.orgName,
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  otherIndustry: userSetupState.otherInterest || undefined,
                  industryValueIds: (userSetupState.interests ?? [])
                    .filter((industry) => industry !== INDUSTRY_ATTRIBUTE_VALUE_OTHER.valueId)
                });
                setProceed(true);
              }}
            >
              <FormattedMessage
                defaultMessage="Start using {app}"
                description="Acceptance button label"
                values={{ app: APP_PLATFORM }}
              />
            </Button>
          </footer>
        </div>
      </div>
    </div>
  );
}
