import { initialState as appInitialState } from 'reducers/app';

import { RootState } from '..';

export function applyDefaultValues(rehydratedState: Partial<RootState>) {
  return {
    app: {
      ...appInitialState,
      ...rehydratedState.app
    }
  };
}
