import { makeStyles, shorthands, tokens } from '@mtabnpd/mtab-design-system';

export const useCookieConsentStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    height: '5rem',
    opacity: 0.95,
    backgroundColor: tokens.colorBrandBackground2,
    color: tokens.colorBrandForeground1
  },
  button: {
    ...shorthands.margin(0, tokens.spacingHorizontalL)
  }
});
