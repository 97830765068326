import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';
import { produce } from 'immer';
import { PasswordChange } from 'types/password';
import {
  UserSettings,
  UserNotificationPreferences,
  UserProfile,
  Tenant,
  AppPromptKey,
  Benefit
} from 'types/user';
import { Navigation } from 'types/navigation';
import { getTimeRemaining } from 'utilities/subscription';
import { api, Tag, UserContentTags } from '../api';
import { DeleteUserRequest, PutUserSettingsRequest, UserSetupRequest } from './user.types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query<UserProfile, void>({
      query: () => '/auth/user/profile',
      transformResponse(response: UserProfile) {
        return prepareUserProfile(response);
      },
      providesTags: () => [Tag.USER, { type: Tag.USER, id: 'profile' }]
    }),
    getUserNavigation: builder.query<Navigation, void>({
      query: () => '/auth/user/navigation',
      providesTags: [Tag.NAVIGATION]
    }),
    getUserSettings: builder.query<UserSettings, void>({
      query: () => '/auth/user/settings',
      onQueryStarted: async (_, { queryFulfilled, updateCachedData }) => {
        const { data: userSettings } = await queryFulfilled;

        // avoid immer here - after updates we always want a new response object
        // during user settings update backend may clean user submitted values
        updateCachedData(() => userSettings);
      },
      providesTags: () => [Tag.USER, { type: Tag.USER, id: 'settings' }]
    }),
    getUserBenefits: builder.query<Benefit[], string>({
      query: (benefitId) => `/auth/user/benefits/${benefitId}`
    }),
    updateUserSettings: builder.mutation<UserProfile, PutUserSettingsRequest>({
      query: (body) => ({
        url: '/auth/user/settings',
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      },
      invalidatesTags: [{ type: Tag.USER, id: 'settings' }]
    }),
    changeTenant: builder.mutation<UserProfile, { userId: string; tenantId: string }>({
      query: (body) => ({
        url: 'auth/tenant/change',
        method: 'POST',
        body
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      },
      invalidatesTags: [{ type: Tag.USER, id: 'settings' }, Tag.NAVIGATION, ...UserContentTags]
    }),
    saveLegalDisclaimerConsent: builder.mutation<UserProfile, void>({
      query: (body) => ({
        url: 'auth/user/disclaimer/acceptance',
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      }
    }),
    saveUserSetup: builder.mutation<UserProfile, UserSetupRequest>({
      query: (body) => ({
        url: 'auth/user/setup',
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      }
    }),
    updateUserNotificationSettings: builder.mutation<void, UserNotificationPreferences>({
      query: (body) => ({
        url: 'auth/user/settings/notifications',
        method: 'PUT',
        body
      }),
      onQueryStarted: async (notifications, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(userApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
          draft.notifications = notifications;
        }));
      }
    }),
    updateUserPassword: builder.mutation<void, PasswordChange>({
      query: (body) => ({
        url: 'auth/user/change-password',
        method: 'POST',
        body
      })
    }),
    updateUserImpersonation: builder.mutation<UserProfile, Tenant>({
      query: ({ userId, tenantId }) => ({
        url: '/auth/user/impersonate',
        method: 'POST',
        body: { userId, tenantId }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      },
      invalidatesTags: [Tag.NAVIGATION, ...UserContentTags]
    }),
    clearUserImpersonation: builder.mutation<UserProfile, void>({
      query: () => ({
        url: '/auth/user/impersonate',
        method: 'DELETE'
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: userProfile } = await queryFulfilled;
        dispatch(updateUserProfileCache(userProfile));
      },
      invalidatesTags: [Tag.NAVIGATION, ...UserContentTags]
    }),
    clearApplicationPrompt: builder.mutation<UserProfile, AppPromptKey>({
      query: (key) => ({
        url: `/auth/user/prompts/${key}`,
        method: 'DELETE'
      }),
      onQueryStarted: async (key, { dispatch, queryFulfilled }) => {
        // optimistic to remove prompt from ui as quickly as possible
        const patchResult = dispatch(
          userApi.util.updateQueryData(
            'getUserProfile',
            undefined,
            (draft) => {
              draft.prompts = draft.prompts
                .filter((promptKey) => promptKey !== key);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    deleteUserAccount: builder.mutation<void, DeleteUserRequest>({
      query: (body) => ({
        url: '/auth/user/profile/delete',
        method: 'POST',
        body
      })
    })
  })
});

const prepareUserProfile = (userProfile: UserProfile) => produce(userProfile, (draft) => {
  // Immer is used
  // queryFulfilled returns non-extensible object (Object.preventExtensions())
  if (draft.subscription.detail) {
    draft.subscription.detail.timeRemaining = getTimeRemaining(draft.subscription.detail);
  }

  if (draft.mtabPlusSubscription) {
    draft.mtabPlusSubscription.timeRemaining = getTimeRemaining(draft.mtabPlusSubscription);
  }
});

const updateUserProfileCache = (userProfile: UserProfile) => userApi.util.updateQueryData(
  'getUserProfile',
  undefined,
  () => prepareUserProfile(userProfile)
);

export const {
  useGetUserSettingsQuery,
  useGetUserBenefitsQuery,
  useChangeTenantMutation,
  useSaveUserSetupMutation,
  useUpdateUserSettingsMutation,
  useUpdateUserPasswordMutation,
  useClearUserImpersonationMutation,
  useUpdateUserImpersonationMutation,
  useSaveLegalDisclaimerConsentMutation,
  useUpdateUserNotificationSettingsMutation,
  useClearApplicationPromptMutation,
  useDeleteUserAccountMutation
} = userApi;

const { useGetUserProfileQuery, useGetUserNavigationQuery } = userApi;

export type UseGetUserProfileQueryResult = Omit<ReturnType<typeof useGetUserProfileQuery>, 'refetch'>;

export const useGetUserProfile: typeof useGetUserProfileQuery = (arg, options = {}) => {
  const { authState } = useAuthProvider();
  const isAuthed = authState?.isAuthenticated;

  return useGetUserProfileQuery(arg, {
    skip: !isAuthed,
    ...options
  });
};

export const useGetUserNavigation: typeof useGetUserNavigationQuery = (arg, options = {}) => {
  const { authState } = useAuthProvider();
  const isAuthed = authState?.isAuthenticated;

  return useGetUserNavigationQuery(arg, {
    skip: !isAuthed,
    ...options
  });
};
