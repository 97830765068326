import { SuspenseIconProps } from '@mtabnpd/mtab-design-icons/react';

export interface Application {
  applicationId: string;
  name: string;
  description: string;
  iconName: SuspenseIconProps['iconName'];
  category: ApplicationCategory;
  url: string;
}

export enum ApplicationCategory {
  OTHER = 'OTHER',
  ADMIN = 'ADMIN'
}

export enum ApplicationType {
  HOME_PORTAL = 'HOME_PORTAL',
  MARKETPLACE = 'MARKETPLACE',
  ANALYZE = 'ANALYZE',
  DISCOVER = 'DISCOVER',
  DAAS = 'DAAS',
  STORIES = 'STORIES',
  MTAB = 'MTAB', // mTab classic
  DATA_PROVIDER = 'DATA_PROVIDER',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  V10_ADMIN = 'V10_ADMIN',
  FAVORITES = 'FAVORITES',
  SHARED = 'SHARED',
  TUTORIALS = 'TUTORIALS',
  RECENT = 'RECENT',
  SUPPORT = 'SUPPORT',
  REPORTS = 'REPORTS'
}
