import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';

import { ROUTE_DEFAULT } from 'constants/routes';

export function LoginWithIDP() {
  const { authState, oktaAuth } = useAuthProvider();
  const { idp } = useParams();

  useEffect(() => {
    if (idp && !authState?.isAuthenticated) {
      oktaAuth.signInWithRedirect({
        originalUri: ROUTE_DEFAULT,
        idp
      });
    }
  }, [idp, oktaAuth, authState]);

  if (!idp || authState?.isAuthenticated) {
    return (
      <Navigate to={ROUTE_DEFAULT} replace />
    );
  }

  return null;
}
