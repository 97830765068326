import { forwardRef } from 'react';
import {
  Button,
  ButtonProps,
  Spinner,
  makeStyles,
  mergeClasses
} from '@mtabnpd/mtab-design-system';

const useSubmitButtonStyles = makeStyles({
  root: {
    width: 'fit-content'
  }
});

type SubmitButtonProps = ButtonProps & {
  isLoading?: boolean;
};

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(({
  className,
  children,
  isLoading,
  ...props
}, ref) => {
  const submitButtonStyles = useSubmitButtonStyles();
  return (
    <Button
      ref={ref}
      type="submit"
      className={mergeClasses(
        submitButtonStyles.root,
        className
      )}
      icon={isLoading ? <Spinner size="extra-tiny" /> : null}
      data-test="button-submit"
      {...props}
    >
      {children}
    </Button>
  );
});
