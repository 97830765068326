import { HTMLAttributes, ReactNode, useState } from 'react';

import { classnames } from 'utilities/classnames';
import { isEllipsisActive } from 'utilities/dom';

import { FontSize } from './Text.consts';

import './Text.scss';

export interface TextProps extends HTMLAttributes<HTMLDivElement> {
  header?: boolean;
  subTitle?: boolean;
  info?: boolean;
  accent?: boolean;
  ellipsis?: boolean;
  ellipsisTooltip?: string;
  className?: string;
  size?: FontSize;
  inline?: boolean;
  children: ReactNode;
}

export function Text({
  inline = false,
  header = false,
  subTitle = false,
  accent = false,
  info = false,
  ellipsis = false,
  ellipsisTooltip,
  children,
  className,
  size = FontSize.NORMAL,
  ...props
}: TextProps) {
  const [ellipsisActive, setEllipsisActive] = useState(false);
  const detectEllipsis = (element: HTMLDivElement) => {
    if (!ellipsis || !element) {
      return;
    }

    setEllipsisActive(isEllipsisActive(element));
  };

  const classes = classnames({
    'c-text': true,
    'c-text--inline': inline,
    'c-text--sub-title': subTitle,
    'c-text--header': header,
    'c-text--info': info,
    'c-text--accent': accent,
    'c-text--ellipsis': ellipsis,
    'c-text--small': size === FontSize.SMALL,
    'c-text--normal': size === FontSize.NORMAL,
    'c-text--large': size === FontSize.LARGE,
    'c-text--huge': size === FontSize.HUGE,
    [className ?? '']: !!className
  });

  return (
    <div
      ref={detectEllipsis}
      className={classes}
      title={ellipsisActive ? ellipsisTooltip : undefined}
      {...props}
    >
      {children}
    </div>
  );
}
