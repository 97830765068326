import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { Video, Phone, BookOpen } from '@mtabnpd/mtab-design-icons/react';
import { AppHeader } from '@mtabnpd/mtab-app-header';

import { ROUTE_FEED_TUTORIALS } from 'constants/routes';

import { InfoHeaderMenuItem } from './InfoHeaderMenuItem';
import {
  CONTACT_LABEL,
  CONTACT_URL,
  TUTORIALS_LABEL,
  USER_GUIDE_LABEL,
  USER_GUIDE_URL
} from './InfoHeaderMenu.consts';

type AppInfoProps = ComponentProps<typeof AppHeader>['appInfo'];

export const getInfoHeaderMenuDefinition = () : AppInfoProps => ({
  itemsGroup: [
    [{
      element: <InfoHeaderMenuItem icon={Video} title={TUTORIALS_LABEL} />,
      elementType: 'link',
      elementProps: {
        component: Link,
        to: ROUTE_FEED_TUTORIALS
      }
    }],
    [{
      element: <InfoHeaderMenuItem icon={BookOpen} title={USER_GUIDE_LABEL} />,
      elementType: 'link',
      elementProps: {
        href: USER_GUIDE_URL
      }
    }],
    [{
      element: <InfoHeaderMenuItem icon={Phone} title={CONTACT_LABEL} />,
      elementType: 'link',
      elementProps: {
        href: CONTACT_URL
      }
    }]
  ]
});
