import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mtabnpd/mtab-platform-core';
import classNames from 'classnames';

import './SubscriptionDetails.scss';

interface SubscriptionDetailsProps {
  paymentsUrls: string;

  subscriptionButtonText?: ReactNode;
  columnLayout?: boolean;
}

function ManageSubscription({
  subscriptionButtonText,
  paymentsUrls
}: Pick<SubscriptionDetailsProps, 'subscriptionButtonText' | 'paymentsUrls'>) {
  return (
    <a href={paymentsUrls} rel="noreferrer">
      <Button
        className="c-subscription-details__subscribe-button"
      >
        {subscriptionButtonText || (
          <FormattedMessage
            defaultMessage="Subscribe"
            description="Button label"
          />
        )}
      </Button>
    </a>
  );
}

export function SubscriptionDetails({
  subscriptionButtonText,
  paymentsUrls,
  columnLayout = false
}: SubscriptionDetailsProps) {
  return (
    <div className={classNames('c-subscription-details', columnLayout && 'c-subscription-details--column')}>
      <ManageSubscription
        paymentsUrls={paymentsUrls}
        subscriptionButtonText={subscriptionButtonText}
      />
    </div>
  );
}
