import { useEffect, useRef } from 'react';
import { useAuthProvider } from '@mtabnpd/mtab-client-auth-js';

import { useUserClaims } from 'hooks/userContext';

import { initializeZendeskWidget } from './zendesk';

export function useSupportWidget(type?: 'zendesk') {
  const widgetIsMounted = useRef(false);
  const { authState } = useAuthProvider();
  const { superAdmin } = useUserClaims();
  const isAuthenticated = authState?.isAuthenticated ?? false;

  useEffect(() => {
    if (!isAuthenticated || widgetIsMounted.current) {
      // the widget script may not clean up any generated elements
      // safer to just run it once
      return;
    }

    const cleanupIntegration = type && initializeZendeskWidget({ superAdmin });

    widgetIsMounted.current = true;

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      cleanupIntegration?.();
    };
  }, [isAuthenticated, superAdmin, type]);
}
