import { FeatureFlagsAPI, useFeatureFlag as useAnyFeatureFlag } from '@mtabnpd/mtab-platform-core';

import {
  INCLUDE_FULL_STORY,
  INCLUDE_USER_PROFILE_PULSE,
  INCLUDE_USER_SETUP_INTERCEPTOR,
  INCLUDE_FEED_TUTORIALS,
  INCLUDE_SUBSCRIBE_MTAB_PLUS,
  INCLUDE_ZENDESK_INTEGRATION,
  INCLUDE_ZENDESK_LOGGER
} from './flags';

type FeatureFlag =
  | 'ENABLE_FULL_STORY'
  | 'ENABLE_USER_PROFILE_PULSE'
  | 'ENABLE_USER_SETUP_INTERCEPTOR'
  | 'ENABLE_FEED_TUTORIALS'
  | 'ENABLE_SUBSCRIBE_MTAB_PLUS'
  | 'ENABLE_ZENDESK_INTEGRATION'
  | 'ENABLE_ZENDESK_LOGGER';

export function getFeatureFlags(): Record<FeatureFlag, boolean> {
  return {
    ENABLE_FULL_STORY: INCLUDE_FULL_STORY,
    ENABLE_USER_PROFILE_PULSE: INCLUDE_USER_PROFILE_PULSE,
    ENABLE_USER_SETUP_INTERCEPTOR: INCLUDE_USER_SETUP_INTERCEPTOR,
    ENABLE_FEED_TUTORIALS: INCLUDE_FEED_TUTORIALS,
    ENABLE_SUBSCRIBE_MTAB_PLUS: INCLUDE_SUBSCRIBE_MTAB_PLUS,
    ENABLE_ZENDESK_INTEGRATION: INCLUDE_ZENDESK_INTEGRATION,
    ENABLE_ZENDESK_LOGGER: INCLUDE_ZENDESK_LOGGER
  };
}

export const useFeatureFlag = () => useAnyFeatureFlag() as unknown as FeatureFlagsAPI<FeatureFlag>;
