import {
  forwardRef,
  HTMLAttributes, ReactNode
} from 'react';

import { classnames } from 'utilities/classnames';

import './Container.scss';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  fluid?: boolean;
  flex?: boolean;
  column?: boolean;
  row?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(({
  flex = false,
  fluid = false,
  column = false,
  row = false,
  className,
  children,
  ...props
}, ref) => {
  const classes: Record<string, boolean> = {
    'c-container': true,
    'c-container--flex-row': flex && row,
    'c-container--flex-col': flex && column,
    'c-container--fluid': fluid
  };

  if (className) {
    classes[className] = true;
  }

  return (
    <div ref={ref} className={classnames(classes)} {...props}>
      {children}
    </div>
  );
});
