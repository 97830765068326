import { useEffect, useState } from 'react';
import { Text } from 'components/text';
import { UserContextPicker } from 'components/userContextPicker';

import './TenantSwitcherContent.scss';
import { Tenant } from 'types/user';

interface TenantSwitcherContentProps {
  currentTenant: Tenant;
  selectedTenant?: Tenant;
  tenants: Tenant[];
  setSelectedTenant: (tenant: Tenant) => void;
}

function normalize(value: string) {
  return value.trim().toLowerCase();
}

export function TenantSwitcherContent({
  currentTenant, selectedTenant, tenants, setSelectedTenant
}: TenantSwitcherContentProps) {
  const [visibleTenantUsers, setVisibleTenantUsers] = useState<Tenant[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!searchText) {
      setVisibleTenantUsers(tenants);
      return;
    }
    const searchString = normalize(searchText);
    setVisibleTenantUsers(tenants.filter((tenantUser) => (
      normalize(tenantUser.userEmail).includes(searchString)
        || normalize(tenantUser.displayName).includes(searchString)
        || normalize(tenantUser.tenantLabel).includes(searchString)
    )));
  }, [searchText, tenants]);

  return (
    <div className="c-tenant-switcher-content">
      <div className="c-tenant-switcher-content__current">
        <span className="c-tenant-switcher-content__current-indicator">Current</span>
        <span className="c-tenant-switcher-content__current-name">
          {currentTenant.tenantLabel}
          <Text info>
            {`${currentTenant.displayName} (${currentTenant.userEmail})`}
          </Text>
        </span>
      </div>
      <UserContextPicker
        users={visibleTenantUsers}
        selectedUserId={selectedTenant?.userId}
        onSelection={setSelectedTenant}
        onSearch={(value) => setSearchText(value)}
      />
    </div>
  );
}
