import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { createStore } from './createStore';
import { loadState } from './persist';
import { queryErrorMiddleware } from './middleware/errorHandling';

export const store = createStore({
  preloadedState: loadState(),
  extraMiddleware: [queryErrorMiddleware],
  subscribe: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: { activity: ActivityState, users: UsersState, ...etc }
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
  >;
export type ThunkAPIConfig = {
  state: RootState;
  dispatch: AppDispatch;
};
