import { useState, useMemo } from 'react';

import { getUsers } from 'services/impersonation';
import { Tenant } from 'types/user';
import { debounce } from 'utilities/debounce';

const MIN_CHARS_TO_SEARCH = 3;
const SEARCH_DEBOUNCE = 500;

export function useFetchUsers() : [(text: string) => void, Tenant[], boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUsers, setCurrentUsers] = useState<Tenant[]>([]);

  const fetchUsers = useMemo(
    () => debounce((searchText: string) => {
      if (searchText.length < MIN_CHARS_TO_SEARCH) {
        setCurrentUsers([]);
        return;
      }

      setIsLoading(true);
      getUsers(searchText).then((users) => {
        setCurrentUsers(users);
      })
        .catch(() => {
          setCurrentUsers([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, SEARCH_DEBOUNCE),
    []
  );

  return [fetchUsers, currentUsers, isLoading];
}
