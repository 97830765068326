import { useIntl } from 'react-intl';
import { Search } from '@mtabnpd/mtab-design-icons/react';
import { Input, VirtualTable } from '@mtabnpd/mtab-platform-core';
import { Tenant } from 'types/user';

import { useColumns } from './UserContextPicker.hooks';

import './UserContextPicker.scss';

interface UserContextPickerProps {
  users: Tenant[];
  onSelection: (user: Tenant) => void;
  onSearch: (searchText: string) => void;
  selectedUserId: string | undefined;

  isLoading?: boolean;
}

export function UserContextPicker({
  isLoading,
  users,
  onSearch,
  onSelection,
  selectedUserId
}: UserContextPickerProps) {
  const intl = useIntl();
  const columns = useColumns(onSelection, selectedUserId);
  return (
    <div className="c-user-context-picker">
      <Input
        className="c-user-context-picker__search"
        prefix={<Search size={16} />}
        size="middle"
        allowClear
        placeholder={intl.formatMessage({ defaultMessage: 'Find user', description: 'Search placeholder' })}
        onChange={(e) => onSearch(e.target.value)}
      />
      <VirtualTable
        wrapperClassName="c-user-context-picker__table"
        loading={isLoading}
        columns={columns}
        dataSource={users}
        rowKey="userId"
        resizableColumns
        onRow={(record) => ({
          className: 'c-user-context-picker__table-row',
          onClick: () => onSelection(record)
        })}
      />
    </div>
  );
}
