import { addAuth, request } from 'services/http';
import { withBasename } from 'utilities/url';

const noopResponseAccessor = () => { /* noop */ };

export class Logger {
  static readonly POST_LOG_URL = withBasename('/api/v1/auth/logger/log');

  static log(value: unknown) {
    // eslint-disable-next-line no-console
    console.log(value);
    Logger.persistLogEntry(String(value));
  }

  static persistLogEntry(value: string) {
    const options: RequestInit = {
      method: 'POST',
      headers: {
        ContentType: 'text/plain'
      },
      body: value
    };

    request<void>(Logger.POST_LOG_URL, addAuth(options), noopResponseAccessor);
  }
}
