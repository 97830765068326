import { PropsWithChildren } from 'react';
import { FeatureFlagProvider } from '@mtabnpd/mtab-platform-core';
import { Provider } from 'react-redux';
import { RawIntlProvider } from 'react-intl';

import { getFeatureFlags } from 'lib/features';
import { intl } from 'lib/i18n';
import { Router } from 'lib/router';
import { store } from 'store';

export function AppContext({ children }: PropsWithChildren<any>) {
  return (
    <FeatureFlagProvider flags={getFeatureFlags()}>
      <Provider store={store}>
        <Router>
          <RawIntlProvider value={intl}>
            {children}
          </RawIntlProvider>
        </Router>
      </Provider>
    </FeatureFlagProvider>
  );
}
