import { BadgeType } from '@mtabnpd/mtab-app-header';
import { UserProfile, UserSubscription, UserSubscriptionType } from 'types/user';

import { UseGetUserProfileQueryResult } from '../user';

function predicatePlan(
  typeFn: (type: UserSubscriptionType) => boolean,
  detailFn: (detail: UserSubscription) => boolean
) {
  return function predicate(subscription: UserProfile['subscription']) {
    return typeFn(subscription.type)
    && subscription.detail
    && detailFn(subscription.detail);
  };
}

const isPaidPremiumPlan = predicatePlan(
  (type) => type === UserSubscriptionType.SUBSCRIBED,
  (detail) => detail.cost > 0
);
const isTrialPremiumPlan = predicatePlan(
  (type) => type === UserSubscriptionType.TRIAL,
  () => true
);
const isFreePlan = predicatePlan(
  (type) => type === UserSubscriptionType.SUBSCRIBED,
  (detail) => detail.cost === 0
);

export function selectUserBadges(result: Partial<UseGetUserProfileQueryResult>) {
  const { data: profile }: { data?: UserProfile } = result;
  const badges: BadgeType[] = [];

  if (!profile) {
    return badges;
  }

  const { mtabPlus, subscription } = profile;

  if (mtabPlus) {
    badges.push('MEMBERSHIP_SUBSCRIPTION');
  }

  if (isPaidPremiumPlan(subscription)
    || isTrialPremiumPlan(subscription)) {
    badges.push('PREMIUM_SUBSCRIPTION');
  }

  if (isFreePlan(subscription)) {
    badges.push('FREE_SUBSCRIPTION');
  }

  return badges;
}
