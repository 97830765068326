import { useRef } from 'react';
import { Modal } from '@mtabnpd/mtab-platform-core';

import { getDefaultModalPosition } from 'utilities/modal';

import { ImpersonationWrapperProps } from '../impersonationWrapper';

import './ImpersonationModal.scss';

const DEFAULT_MODAL_WIDTH = 600;
const DEFAULT_MODAL_HEIGHT = 500;

export interface ImpersonationModalProps extends Omit<ImpersonationWrapperProps, 'mode'> {}

export function ImpersonationModal({
  visible,
  applyDisabled,
  onApply,
  onClose,
  children
}: ImpersonationModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const modalContainer = containerRef.current;

  return (
    <>
      <div ref={containerRef} />
      {modalContainer && (
        <Modal
          title="Log in as another user"
          className="c-impersonation-modal"
          visible={visible}
          onOk={onApply}
          onCancel={onClose}
          closable={false}
          okButtonProps={{ disabled: applyDisabled }}
          getContainer={() => modalContainer}
          resizable
          defaultPosition={getDefaultModalPosition(DEFAULT_MODAL_WIDTH, DEFAULT_MODAL_HEIGHT)}
          handleOffset={-5}
        >
          {children}
        </Modal>
      )}
    </>
  );
}
