import { useEffect } from 'react';

import { getIndustryValues } from 'actions/app';
import { INDUSTRY_ATTRIBUTE_KEY } from 'constants/app';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectAttributes } from 'reducers/app';
import { useGetUserSettingsQuery, useGetUserProfile } from 'services/api/user';
import { UserAccountProvider } from 'types/user';

export function useIndustryAttribute() {
  const dispatch = useAppDispatch();
  const industries = useAppSelector((state) => selectAttributes(state, INDUSTRY_ATTRIBUTE_KEY));

  useEffect(() => {
    dispatch(getIndustryValues());
  }, [dispatch]);

  return industries;
}

export function useUserSettings() {
  const { data: userSettings, isFetching } = useGetUserSettingsQuery();
  const { data: userProfile } = useGetUserProfile();
  const {
    otherIndustry,
    accountProvider,
    attributes = []
  } = userSettings || {};
  const requiresIndustries = otherIndustry !== undefined
    || attributes.some(({ keyId }) => keyId === INDUSTRY_ATTRIBUTE_KEY);

  return {
    userSettings,
    accountType: userProfile?.subscription.type,
    platformSubscription: userProfile?.subscription.detail,
    paymentsUrl: userProfile?.paymentsUrl,
    requiresIndustries,
    isExternal: accountProvider === UserAccountProvider.EXTERNAL,
    isLoading: isFetching
  };
}
