import {
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  retry
} from '@reduxjs/toolkit/query/react';

import { OUTAGE_URL } from 'constants/http';
import { authProvider } from 'lib/auth';
import { withBasename } from 'utilities/url';

const unAuthedAPIs = ['getTutorials', 'getFeaturedContent'];

const baseQuery = fetchBaseQuery({
  baseUrl: withBasename('/api/v1'),
  prepareHeaders: (headers, { endpoint }) => {
    if (unAuthedAPIs.includes(endpoint)) {
      return headers;
    }
    const authorization = `Bearer ${authProvider.auth.getAccessToken()}`;
    headers.set('Authorization', authorization);
    return headers;
  }
});

export const query = retry(async (args: string | FetchArgs, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.meta?.response
    && isOutageRedirect(result.meta.response)) {
    window.location.assign(result.meta.response.url);
  }

  if (result.error && !shouldRetry(result)) {
    retry.fail(result.error);
  }

  return result;
}, { maxRetries: 5 });

function isOutageRedirect(response: Response) {
  return response.redirected && response.url === OUTAGE_URL;
}

function isServerError(status: number) {
  return status >= 500 && status < 600;
}

function shouldRetry({
  error
}: { error: FetchBaseQueryError; meta?: FetchBaseQueryMeta }) {
  return typeof error.status === 'string' || isServerError(error.status);
}
