/* eslint-disable max-len */
import { parseBooleanFlag } from '@mtabnpd/mtab-platform-core';

export const INCLUDE_USER_PROFILE_PULSE = parseBooleanFlag(
  process.env.REACT_APP_FEATURE_USER_CONTEXT_PULSE
);
export const INCLUDE_FULL_STORY = parseBooleanFlag(process.env.REACT_APP_FEATURE_FULL_STORY);
export const INCLUDE_USER_SETUP_INTERCEPTOR = parseBooleanFlag(
  process.env.REACT_APP_FEATURE_USER_SETUP
);
export const INCLUDE_FEED_TUTORIALS = parseBooleanFlag(process.env.REACT_APP_FEATURE_FEED_TUTORIALS);
export const INCLUDE_SUBSCRIBE_MTAB_PLUS = parseBooleanFlag(process.env.REACT_APP_FEATURE_SUBSCRIBE_MTAB_PLUS);
export const INCLUDE_ZENDESK_INTEGRATION = parseBooleanFlag(process.env.REACT_APP_FEATURE_ZENDESK_INTEGRATION);
export const INCLUDE_ZENDESK_LOGGER = parseBooleanFlag(process.env.REACT_APP_FEATURE_ZENDESK_LOGGING);
