import { ZENDESK_SCRIPT_URL } from 'constants/app';
import { getFeatureFlags } from 'lib/features';
import { Logger } from 'lib/logger';
import { withBasename } from 'utilities/url';

import { SupportWidgetInit } from '../supportWidget.types';
import { DefaultTokenService } from './TokenService';

export const initializeZendeskWidget: SupportWidgetInit<{ superAdmin: boolean }> = ({
  superAdmin
}) => {
  const scriptSrc = ZENDESK_SCRIPT_URL;
  const enableZendeskLogging = superAdmin && getFeatureFlags().ENABLE_ZENDESK_LOGGER;

  if (!scriptSrc) {
    console.error('Missing Zendesk script URL');
    return;
  }

  const tokenService = new DefaultTokenService(withBasename('/api/v1/auth/user/zendesk/chat/jwt'));
  const script = document.createElement('script');

  script.id = 'ze-snippet';
  script.src = scriptSrc;
  script.onload = () => {
    const zendesk = (window as any).zE;
    zendesk('messenger', 'loginUser', (callback: any) => {
      tokenService.fetchToken()
        .then((token) => {
          if (enableZendeskLogging) {
            Logger.log(`Zendesk auth using token = ${token}`);
          }

          callback(token);
        })
        .catch(() => {
          console.error('Unable to start support chat');
        });
    });
  };
  document.head.appendChild(script);
};
