export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_NO_CONTENT = 204;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_ACCEPTED = 202;

export const OUTAGE_URL = process.env.REACT_APP_OUTAGE_REDIRECT_URL;

export const HTTP_ERROR_STATUS_LABEL : Record<number, string> = {
  [HTTP_STATUS_UNPROCESSABLE_ENTITY]: 'Unable to save your changes',
  [HTTP_STATUS_BAD_REQUEST]: 'Data was sent in an unrecognized format',
  [HTTP_STATUS_UNAUTHORIZED]: 'Not allowed, unable to complete your request',
  [HTTP_STATUS_NOT_FOUND]: 'Requested resource not found',
  [HTTP_STATUS_NO_CONTENT]: 'Requested entity not found',
  [HTTP_STATUS_INTERNAL_SERVER_ERROR]: 'Something went wrong, the server could not complete your request'
};

export const UNKNOWN_HTTP_STATUS_LABEL = 'Request failed';
