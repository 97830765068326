import 'lib/polyfill';
import 'lib/analytics';
import 'lib/dayjs';

import '@mtabnpd/mtab-platform-core/dist/styles/index.css';
import '@mtabnpd/mtab-client-auth-js/dist/index.css';
import './resources/styles/global.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { DesignSystemProvider } from '@mtabnpd/mtab-design-system';

import { registerPWA } from 'pwa';
import { withBasename } from 'utilities/url';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContext } from './AppContext';
import { AppSecurityContext } from './AppSecurityContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppContext>
      <AppSecurityContext>
        <DesignSystemProvider fontAssetPath={withBasename('static/media/fonts')}>
          <App />
        </DesignSystemProvider>
      </AppSecurityContext>
    </AppContext>
  </React.StrictMode>
);

registerPWA();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
