import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTE_FEED_SEARCH } from 'constants/routes';

export const MIN_SEARCH_TERM_LENGTH = 1;

function validateSearchTerm(value: string) {
  return !value || value.length > MIN_SEARCH_TERM_LENGTH;
}

export function useSearch(): [
  value: string,
  isValid: boolean,
  setValue: (s: string) => void,
  search: () => void
] {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('query') ?? '';
  const [controlledSearchValue = '', setControlledSearchValue] = useState(searchTerm);
  const [searchValueIsValid, setSearchValueIsValid] = useState(validateSearchTerm(searchTerm));
  const navigate = useNavigate();

  const setSearchTerm = useCallback((nextSearchValue: string) => {
    setSearchValueIsValid((isValid) => {
      if (!isValid) {
        return validateSearchTerm(nextSearchValue);
      }

      return isValid;
    });
    setControlledSearchValue(nextSearchValue);
  }, []);

  const executeSearch = useCallback(() => {
    const searchTermIsValid = validateSearchTerm(controlledSearchValue);

    setSearchValueIsValid(searchTermIsValid);

    if (!controlledSearchValue || !searchTermIsValid) {
      return;
    }

    if (controlledSearchValue !== searchTerm) {
      const params = new URLSearchParams(window.location.search);

      params.set('query', controlledSearchValue);
      navigate(`${ROUTE_FEED_SEARCH}?${params.toString()}`);
    }
  }, [controlledSearchValue, navigate, searchTerm]);

  useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm, setSearchTerm]);

  return [controlledSearchValue, searchValueIsValid, setSearchTerm, executeSearch];
}
