import { useState } from 'react';

import { UserContextPicker } from 'components/userContextPicker';

import { useGetUserProfile, useUpdateUserImpersonationMutation } from 'services/api/user';
import { Tenant } from 'types/user';
import { ImpersonationWrapper } from './impersonationWrapper';
import { useFetchUsers } from './Impersonation.hooks';

export interface ImpersonationProps {
  onApply: () => void;
  onClose: () => void;
  onClear?: () => void;
  mode: 'modal' | 'content' | 'modalApp';
  visible: boolean;
}

export function Impersonation({
  mode,
  visible,
  onApply,
  onClose,
  onClear
}: ImpersonationProps) {
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const { data: userProfile } = useGetUserProfile();
  const onSelection = (tenantUser: Tenant) => setSelectedUserId(tenantUser.userId);
  const [fetchUsers, currentUsers, isLoading] = useFetchUsers();
  const [updateUserImpersonation] = useUpdateUserImpersonationMutation();
  const selectedUser = selectedUserId && currentUsers.find((user) => user.userId === selectedUserId);
  const userImpersonation = userProfile?.impersonation;

  const onImpersonate = () => {
    if (selectedUser) {
      updateUserImpersonation(selectedUser).then(() => onApply());
    }
  };

  return (
    <ImpersonationWrapper
      mode={mode}
      visible={visible}
      applyDisabled={!selectedUser}
      impersonating={!!userImpersonation?.impersonating}
      onApply={onImpersonate}
      onClose={onClose}
      onClear={onClear}
    >
      <UserContextPicker
        users={currentUsers}
        isLoading={isLoading}
        onSearch={fetchUsers}
        onSelection={onSelection}
        selectedUserId={selectedUserId}
      />
    </ImpersonationWrapper>
  );
}
