import { ReactElement } from 'react';

import { TenantSwitcherProps } from '../TenantSwitcher';
import { TenantSwitcherModal } from '../tenantSwitcherModal';

export interface TenantSwitcherWrapperProps extends Omit<TenantSwitcherProps, 'tenants'> {
  onAccept: () => void;
  loading: boolean;
  children: ReactElement;
}

export function TenantSwitcherWrapper({
  mode,
  children,
  ...rest
} : TenantSwitcherWrapperProps) {
  return (
    <TenantSwitcherModal overlay={mode === 'modalApp'} {...rest}>
      {children}
    </TenantSwitcherModal>
  );
}
