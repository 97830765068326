import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { INDUSTRY_ATTRIBUTE_MAX, MAX_INPUT_LENGTH_UNLIMITED } from 'constants/app';

export function useValidationRules(showOther: boolean = false, otherIsTouched: boolean = false) {
  const intl = useIntl();

  return useMemo(() => ({
    orgNameRules: {
      validate: (value?: string) => {
        if (value === undefined) {
          return true;
        }

        const len = value.trim().length;

        if (len > 1 && len <= 200) {
          return true;
        }

        return intl.formatMessage({ defaultMessage: 'Your organization is required', description: 'Validation failure' });
      }
    },
    interestsRules: {
      type: 'array' as const,
      min: 1,
      max: INDUSTRY_ATTRIBUTE_MAX,
      message: ''
    },
    otherInterestRules: {
      validate: (value: string = '') => {
        if ((!value && !otherIsTouched) || !showOther) {
          return true;
        }

        const len = value.trim().length;

        if (len > 2 && len <= MAX_INPUT_LENGTH_UNLIMITED) {
          return true;
        }

        return intl.formatMessage({ defaultMessage: 'Minimum 3 characters', description: 'Validation failure' });
      }
    }
  }), [intl, otherIsTouched, showOther]);
}
