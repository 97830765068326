import { LucideIcon } from '@mtabnpd/mtab-design-icons/react';

import './InfoHeaderMenuItem.scss';

interface InfoHeaderMenuItemProps {
  title: string;
  icon: LucideIcon;
}

export function InfoHeaderMenuItem({ icon, title } : InfoHeaderMenuItemProps) {
  const IconComponent = icon;

  return (
    <div className="c-info-header-menu-item">
      <IconComponent strokeWidth={1.5} />
      <p>{title}</p>
    </div>
  );
}
