import { authedGet } from 'services/http';

export class DefaultTokenService {
  private readonly tokenUrl: string;

  private tokenPromise: Promise<any> | undefined;

  constructor(url: string) {
    this.tokenUrl = url;
  }

  fetchToken() {
    if (this.tokenPromise) {
      return this.tokenPromise;
    }

    this.tokenPromise = authedGet(
      this.tokenUrl,
      undefined,
      {},
      (response) => response.text()
    );

    this.tokenPromise.finally(() => {
      this.tokenPromise = undefined;
    });

    return this.tokenPromise;
  }
}
