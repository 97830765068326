import { useParams } from 'react-router-dom';
import { AlertTriangle } from '@mtabnpd/mtab-design-icons/react';

import { FontSize, Text } from 'components/text';
import { RouteErrorType } from 'constants/routes';

import { GenericError, NotFoundError, NotOriginalUserError } from './types';

import './Error.scss';

const WARNING_ICON_SIZE = 112;

type ErrorParams = {
  errorType: RouteErrorType;
};

const getErrorMessage = (errorType: RouteErrorType) => {
  switch (errorType) {
    case RouteErrorType.NOT_ORIGINAL_USER:
      return (
        <NotOriginalUserError />
      );
    case RouteErrorType.GENERIC_ERROR:
      return (
        <GenericError />
      );
    case RouteErrorType.NOT_FOUND:
    default:
      return (
        <NotFoundError />
      );
  }
};

export function Error() {
  const { errorType = RouteErrorType.GENERIC_ERROR } = useParams<ErrorParams>();

  return (
    <div className="App-error">
      <div className="App-error__content">
        <div key="header" className="App-error__header">
          <AlertTriangle size={WARNING_ICON_SIZE} />
          <Text header size={FontSize.LARGE}>Something went wrong</Text>
        </div>
        <div key="content" className="App-error__description">
          {getErrorMessage(errorType)}
        </div>
      </div>
    </div>
  );
}
