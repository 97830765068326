import { registerSW } from 'virtual:pwa-register';

import { browserHistory } from 'lib/history';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
  // [::1] is the IPv6 localhost address.
  || window.location.hostname === '[::1]'
  // 127.0.0.0/8 are considered localhost for IPv4.
  || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
const port = parseInt(window.location.port, 10);
const isInPlatformDevPortRange = port && port >= 8000 && port < 9000;

// @ts-ignore
const isE2ETest = Boolean(window.Cypress);

export function registerPWA() {
  if (isE2ETest || (isLocalhost && isInPlatformDevPortRange)) {
    return;
  }

  registerSW({
    immediate: true,
    onRegisteredSW(swUrl, registration) {
      if (registration) {
        // check for service worker updates on route change
        // replicates SW behavior on non SPA
        browserHistory.listen(() => {
          registration.update();
        });
      }
    }
  });
}
