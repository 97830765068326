import {
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit';

import { DEFAULT_FEED_QUERY, DEFAULT_PAGE_SIZE } from 'services/api/feed';
import { RootState } from 'store';
import { FeedQuery } from 'types/feed';
import { userApi } from 'services/api/user';

import {
  ChangePageActionPayload,
  FeedQueryState,
  FilterUpdateStrategy,
  SetFiltersActionPayload
} from './feedQuery.types';
import { constructFilterQuery } from './feedQuery.utils';

const initialState: FeedQueryState = {
  currentQuery: DEFAULT_FEED_QUERY
};

export const feedQuerySlice = createSlice({
  name: 'feedQuery',
  initialState,
  reducers: {
    createFeedQuery: (state, { payload }: PayloadAction<FeedQuery | undefined>) => {
      state.currentQuery = payload ?? DEFAULT_FEED_QUERY;
    },
    setFilters: (state, { payload }: PayloadAction<SetFiltersActionPayload>) => {
      const { values, strategy = FilterUpdateStrategy.REPLACE, resetPage = true } = payload;
      const [filterFields, filterValues, filterOperators] = constructFilterQuery(values);

      if (strategy === FilterUpdateStrategy.REPLACE) {
        state.currentQuery = {
          ...state.currentQuery,
          filterFields,
          filterValues,
          filterOperators
        };
      } else {
        state.currentQuery = {
          ...state.currentQuery,
          filterFields: [...(state.currentQuery?.filterFields ?? []), ...filterFields],
          filterValues: [...(state.currentQuery?.filterValues ?? []), ...filterValues],
          filterOperators: [...(state.currentQuery?.filterOperators ?? []), ...filterOperators]
        };
      }

      if (resetPage) {
        state.currentQuery.page = 0;
      }
    },
    changePage: (state, { payload }: PayloadAction<ChangePageActionPayload>) => {
      if (!state.currentQuery) {
        state.currentQuery = {
          page: 0
        };
      }

      const { destination, relative = false } = payload;
      const currentPage = state.currentQuery.page ?? -1;
      const nextPage = relative ? currentPage + destination : destination;

      state.currentQuery.page = Math.max(0, nextPage);

      if (!state.currentQuery.pageSize) {
        state.currentQuery.pageSize = DEFAULT_PAGE_SIZE;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        userApi.endpoints.changeTenant.matchFulfilled,
        userApi.endpoints.updateUserImpersonation.matchFulfilled,
        userApi.endpoints.clearUserImpersonation.matchFulfilled
      ),
      (state) => {
        if (state.currentQuery) {
          state.currentQuery.page = 0;
        }
      }
    );
  }
});

const { actions, reducer } = feedQuerySlice;

export const { createFeedQuery, changePage, setFilters } = actions;
export const { name } = feedQuerySlice;

export const selectFeedQuery = (state: RootState) => state.feedQuery.currentQuery;
export const selectSearchValue = createSelector(
  (state: RootState) => state.feedQuery.currentQuery,
  (currentQuery) => currentQuery?.query
);

export default reducer;
