import { UserEnvironmentRedirect } from '@mtabnpd/mtab-client-auth-js';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ROUTE_ERROR, ROUTE_REDIRECT_QUERY_PARAM, RouteErrorType } from 'constants/routes';

export function UserRegionRedirect() {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(ROUTE_REDIRECT_QUERY_PARAM);

  if (!redirectUrl) {
    return (
      <Navigate to={`${ROUTE_ERROR}/${RouteErrorType.GENERIC_ERROR}`} />
    );
  }

  return (
    <UserEnvironmentRedirect
      redirectUrl={redirectUrl}
      secondsTimeout={3}
      redirectText={intl.formatMessage({ defaultMessage: 'Redirecting to' })}
      getAutomaticRedirectText={(seconds) => intl.formatMessage({
        defaultMessage: 'in {remaining} seconds'
      }, {
        remaining: seconds
      })}
    />
  );
}
