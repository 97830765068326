import { useState } from 'react';
import { useChangeTenantMutation, useGetUserProfile } from 'services/api/user';
import { Tenant } from 'types/user';

import { TenantSwitcherContent } from './tenantSwitcherContent';
import { TenantSwitcherWrapper } from './tenantSwitcherWrapper';

export interface TenantSwitcherProps {
  visible: boolean;
  onClose: () => void;
  onAccept: (selectedTenant: Tenant) => void;
  tenants: Tenant[];
  mode: 'modal' | 'modalApp';
}

export function TenantSwitcher({
  visible, onAccept, onClose, tenants, mode
}: TenantSwitcherProps) {
  const [changeTenant] = useChangeTenantMutation();
  const { isLoading: userIsLoading, ...currentTenant } = useGetUserProfile(undefined, {
    selectFromResult: ({ data: profile, isLoading }) => ({
      isLoading,
      tenantId: profile?.tenantId ?? '',
      tenantLabel: profile?.tenantLabel ?? '',
      userId: profile?.userId ?? '',
      displayName: profile?.displayName ?? '',
      userEmail: profile?.email ?? ''
    })
  });

  const [selectedTenant, setSelectedTenant] = useState<Tenant>();

  const handleClose = () => {
    onClose();
    setSelectedTenant(undefined);
  };

  const handleAccept = () => {
    if (!selectedTenant) {
      return;
    }

    const { tenantId, userId } = selectedTenant;

    changeTenant({
      userId,
      tenantId
    }).then(() => {
      onAccept(selectedTenant);
    });
  };

  return (
    <TenantSwitcherWrapper
      mode={mode}
      loading={userIsLoading}
      visible={visible}
      onClose={handleClose}
      onAccept={handleAccept}
    >
      <TenantSwitcherContent
        tenants={tenants || []}
        selectedTenant={selectedTenant}
        currentTenant={currentTenant}
        setSelectedTenant={setSelectedTenant}
      />
    </TenantSwitcherWrapper>
  );
}
