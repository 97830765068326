import { EntityAttributeValue } from 'types';
import { INDUSTRY_ATTRIBUTE_KEY } from 'constants/app';
import { withBasename } from 'utilities/url';

import { authedGet } from '../http';

export function getIndustries() {
  return authedGet<EntityAttributeValue[]>(withBasename('api/v1/auth/content/industries'))
    .then((response) => ({
      [INDUSTRY_ATTRIBUTE_KEY]: response
    }));
}
