import { EntityAttributeValue } from 'types';

export enum AppDisclaimerType {
  COOKIES
}

export interface AppMetaData {
  attributes: Record<string, EntityAttributeValue[]>;
}

export interface AppState extends AppMetaData {
  disclaimers: {
    [key in AppDisclaimerType]?: boolean;
  };
}
