import {
  isRejectedWithValue,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit';

export function isRejectedRTQAction(action: unknown): action is PayloadAction<
  { status: number; data: any },
  string,
  {
    arg?: {
      endpointName?: string;
    };
  },
  SerializedError & { data?: { message?: string } }
> {
  if (isRejectedWithValue(action)) {
    const { meta } = action as any;

    return Boolean(meta.arg?.endpointName);
  }

  return false;
}
