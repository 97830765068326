import { Button, Modal } from '@mtabnpd/mtab-platform-core';
import { useState } from 'react';

import { getDefaultModalPosition } from 'utilities/modal';

import { ImpersonationWrapperProps } from '../impersonationWrapper';

import './ImpersonationModalApp.scss';

const DEFAULT_MODAL_WIDTH = 700;
const DEFAULT_MODAL_HEIGHT = 550;

export interface ImpersonationModalAppProps extends Omit<ImpersonationWrapperProps, 'mode'> {}

export function ImpersonationModalApp({
  visible,
  applyDisabled,
  impersonating,
  onApply,
  onClose,
  onClear,
  children
}: ImpersonationModalAppProps) {
  const [canClear, setCanClear] = useState(true);

  return (
    <Modal
      title="Log in as another user"
      className="c-impersonation-modal-app"
      wrapClassName="c-impersonation-modal-app__overlay"
      visible={visible}
      closable={false}
      footer={[
        <Button key="clear" type="primary" onClick={onClear} disabled={!impersonating || !canClear}>
          Login as original user
        </Button>,
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          disabled={applyDisabled}
          onClick={() => {
            onApply();
            setCanClear(false);
          }}
        >
          OK
        </Button>
      ]}
      resizable
      defaultPosition={getDefaultModalPosition(DEFAULT_MODAL_WIDTH, DEFAULT_MODAL_HEIGHT)}
      handleOffset={-5}
    >
      {children}
    </Modal>
  );
}
