import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@mtabnpd/mtab-platform-core';

import { Text } from 'components/text';
import { PRIVACY_POLICY_URL, TOS_URL } from 'constants/app';

import './LegalDisclaimer.scss';

interface LegalDisclaimerProps {
  msaUrl: string;
  isAccepted?: boolean;
  onAccept: () => void;
  onReject: () => void;
}

export function LegalDisclaimer({
  msaUrl, isAccepted, onAccept, onReject
}: LegalDisclaimerProps) {
  if (!msaUrl.length) {
    throw new Error('Master subscription agreement URL can not be empty');
  }

  return (
    <Checkbox
      className="App-user-setup__legal"
      checked={isAccepted}
      onChange={(e) => {
        const { checked } = e.target;
        if (checked) {
          onAccept();
        } else {
          onReject();
        }
      }}
    >
      <Text>
        <FormattedMessage
          defaultMessage="I agree to mTab's {tos}, {pp} & {msa}"
          description="Legal acceptance checkbox"
          values={{
            tos: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <a href={TOS_URL} target="_blank" rel="noopener noreferrer">
                <FormattedMessage defaultMessage="Terms of Service" description="Link label" />
              </a>
            ),
            pp: (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                <FormattedMessage defaultMessage="Privacy Policy" description="Link label" />
              </a>
            ),
            msa: (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <a href={msaUrl} target="_blank" rel="noopener noreferrer">
                <FormattedMessage defaultMessage="Master Subscription Agreement" description="Link label" />
              </a>
            )
          }}
        />
      </Text>
    </Checkbox>
  );
}
