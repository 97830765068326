import { createAnalytics, AnalyticComponent } from '@mtabnpd/mtab-platform-analytics';

import { APPLICATION_IDENTIFIER, ENABLE_ANALYTICS } from 'constants/app';
import { getFeatureFlags } from 'lib/features';

const { ENABLE_FULL_STORY } = getFeatureFlags();

const analytics = createAnalytics({
  appIdentifier: APPLICATION_IDENTIFIER,
  enable: Boolean(ENABLE_ANALYTICS),
  components: {
    [AnalyticComponent.INTERACTION_TRACKING]: {
      enable: Boolean(ENABLE_ANALYTICS),
      autoInitialize: false
    }
  },
  interactionTrackingOptions: {
    appIdentifier: APPLICATION_IDENTIFIER,
    devMode: !ENABLE_FULL_STORY
  }
});

export const interactionTracking = analytics.interactionTracking?.start();
